import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";

const styles = makeStyles({
  tagsInputContainer: {
    border: `1px solid ${BaseColor.primary}`,
    padding: ".5em",
    borderRadius: "3px",
    width: "100%",
    marginTop: "1em",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: ".5em",
    backgroundColor: BaseColor.whiteColor,
  },
  tagItem: {
    backgroundColor: BaseColor.primary,
    display: "flex",
    padding: ".5em .75em",
    borderRadius: "20px",
    color: "white",
    marginLeft: "5px",
    fontSize: "14px",
    justifyContent: "space-between",
    alignItems: "center",
    // minWidth: "80px",
    fontFamily: FontFamily.Medium,
  },
  close: {
    height: "20px",
    width: "20px",
    backgroundColor: BaseColor.primary,
    color: "white",
    borderRadius: "50%",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "5px",
    cursor: "pointer",
  },

  tagInput: {
    flexGrow: "1",
    border: "none",
    outline: "none",
    padding: ".5em 0",
    backgroundColor: BaseColor.whiteColor,
    fontSize: "16px",
    fontFamily: FontFamily.Regular,
  },

  errorMsgContainer: {
    marginTop: 5,
    marginLeft: 2,
  },
  errorMsgText: {
    fontSize: 14,
    color: BaseColor.errorRed,
    fontFamily: FontFamily.Medium,
  },
});
export default styles;
