import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { Delete, Edit, History } from "@mui/icons-material";
import CModal from "../../Components/CModal";
import theme, { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import _, { isArray, isEmpty, isNull } from "lodash";
import CSelect from "../../Components/CSelect";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import CButton from "../../Components/CButton";
import { useNavigate } from "react-router-dom";
import { createSearchParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//Date Filter
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/";
import styles from "./style";
import CAutoComplete from "../../Components/CAutoComplete/CAutoComplete";
import { PhoneNumberUtil } from "google-libphonenumber";

const userTypeData = [
  {
    id: "created_by_user",
    name: "Created by users",
  },
  {
    id: "created_by_admin",
    name: "Created by admin",
  },
  {
    id: "created_by_all",
    name: "Created by all",
  },
];

const AllUser = () => {
  const navigate = useNavigate();
  const searchRef = useRef();
  const classes = styles();

  // user data and loader and modal
  const [pageLoad, setPageLoad] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [userLoginData, setUserLoginData] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [pageLoadHistory, setPageLoadHistory] = useState(false);
  const [premiumModal, setpremiumModal] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [id, setId] = useState("");
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [premiumBtnLoading, setPremiumBtnLoading] = useState(false);
  const [disablePremiumBtn, setDisablePremiumBtn] = useState(false);
  const [rowChange, setRowChange] = useState(false);
  const [daysValidationError, setDaysValidationError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  // pagination
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [loginPagination, setLoginPagination] = useState();

  // form data state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");

  // search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Country",
    "State",
    "Business Type",
    "Role",
    "Platform type",
    "Status",
    "Posted post count",
    "Reported user count",
    "Created at",
  ]);
  const [userType, setuserType] = useState(
    searchParams.get("user_type") === "created_by_admin"
      ? {
          id: "created_by_admin",
          name: "Created by admin",
        }
      : searchParams.get("user_type") === "created_by_user"
      ? {
          id: "created_by_user",
          name: "Created by user",
        }
      : {
          id: "created_by_all",
          name: "Created by all",
        }
  );

  // get values from URL params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const selectedUserType = searchParams.has("user_type")
    ? searchParams.get("user_type")
    : userType?.id;
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [userIds, setUserIds] = useState(downloadIdArr);

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId,
      selectedUserType
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set data to URL params
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = [],
    user_type = "created_by_user"
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(user_type)) {
      params.user_type = user_type;
    }
    setSearchParams(createSearchParams(params));
  };

  //form validation
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [languageErrorText, setLanguageErrorText] = useState("");
  const [countryErrorText, setCountryErrorText] = useState("");

  const [countryPhoneCode, setCountryPhoneCode] = useState({});

  // date filter
  const [pageNo, setPageNo] = useState(1);
  const [pageNoHistory, setPageNoHistory] = useState(1);
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [days, setDays] = useState("");

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  //phone code
  const [phoneCodeList, setPhoneCodeList] = useState([]);

  //close modal
  const handleClose = () => {
    setModalOpen(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountryPhoneCode(phoneCodeList?.find((v) => v?.phone_code === "+91"));
    setPhone("");
    setPassword("");
    setLanguage("");
    setCountry("");
    setEdit(false);
    allErrorFalse();
  };

  useEffect(() => {
    GetCountryListApi();
    GetLanguageListApi();
    getPhoneCodeList();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(phoneCodeList) && _.isArray(phoneCodeList)) {
      setCountryPhoneCode(phoneCodeList?.find((v) => v?.phone_code === "+91"));
    }
  }, [phoneCodeList]);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    const isSuccess =
      !_.isEmpty(countryList) &&
      _.isArray(countryList) &&
      !_.isEmpty(languageList) &&
      _.isArray(languageList);

    if (isSuccess) {
      getAllUserData(
        selectedUserType,
        page,
        search,
        sortVal,
        sortByVal,
        startDateVal,
        endDateVal,
        downloadId
      );
    }
  }, [countryList, languageList]);

  // country list api
  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  function getPhoneCodeList() {
    const endpoint = `${BaseSetting.endpoint.countriesList}`;
    getApiData(endpoint, "post", { download: true, type: "web" })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setPhoneCodeList(response?.countryData);
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // language list api
  const GetLanguageListApi = () => {
    const endpoint = BaseSetting.endpoint.languageList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setLanguageList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  //all users list
  async function getAllUserData(
    selectedUserType,
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) {
    setDataLoader(true);

    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    if (
      selectedUserType === "created_by_admin" ||
      selectedUserType === "created_by_user"
    ) {
      data.filter_by =
        selectedUserType === "created_by_user" ? "users" : "is_admin";
    }

    getApiData(BaseSetting.endpoint.allUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setUsersData(response?.allUser);
          setPagination(response?.pagination);
          toast(result?.message, { type: "success" });
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          setUsersData([]);
          setPagination({});
          setDataLoader(false);
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  }

  const allErrorFalse = () => {
    setFirstNameErrorText("");
    setLastNameErrorText("");
    setEmailErrorText("");
    setPhoneErrorText("");
    setPasswordErrorText("");
    setLanguageErrorText("");
    setCountryErrorText("");
  };

  // validation
  const validation = (type) => {
    const onlyCharactersRegex = /^[a-zA-Z\s]+$/;
    const onlyNumberRegex = /^[0-9]*$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;

    const passwordRegex =
      /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    const phoneUtil =
      phone.length > 2 && onlyNumberRegex.test(phone)
        ? PhoneNumberUtil.getInstance()
        : null;
    const phoneNumber1 =
      phone.length > 2 && onlyNumberRegex.test(phone)
        ? phoneUtil.parse(phone, countryPhoneCode?.country_code)
        : null;
    const isValidPhoneNumber =
      phone.length > 2 && onlyNumberRegex.test(phone)
        ? phoneUtil.isValidNumber(phoneNumber1)
        : false;

    if (
      !onlyCharactersRegex.test(String(firstName)) &&
      firstName.trim() !== ""
    ) {
      allErrorFalse();
      setFirstNameErrorText("Only characters are allowed");
    } else if (
      !onlyCharactersRegex.test(String(lastName)) &&
      lastName.trim() !== ""
    ) {
      allErrorFalse();
      setLastNameErrorText("Only characters are allowed");
    } else if (email.trim() === "" && phone.trim() === "") {
      allErrorFalse();
      setEmailErrorText("Email required");
    } else if (!emailRegex.test(String(email)) && email.trim() !== "") {
      allErrorFalse();
      setEmailErrorText("Please enter a valid email");
    } else if (phone.trim() === "" && email.trim() === "") {
      allErrorFalse();
      setPhoneErrorText("Phone number required");
    } else if (!isValidPhoneNumber && phone.trim() !== "") {
      allErrorFalse();
      setPhoneErrorText("Please enter a valid phone number");
    }
    //  else if (password.trim() === "") {
    //   allErrorFalse();
    //   setPasswordErrorText("Password required");
    // } else if (!passwordRegex.test(String(password))) {
    //   allErrorFalse();
    //   setPasswordErrorText(
    //     "Password must be contain Upper, Lower, Digit, and 8+ Character"
    //   );
    // }
    else if (String(password)?.length < 6 && password.trim() !== "") {
      allErrorFalse();
      setPasswordErrorText("Password should contain minimum 6 letters");
    } else {
      allErrorFalse();
      if (type === "create") {
        createUser();
      } else {
        updateUser();
      }
    }
  };

  // download data in various formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
      orientation: "landscape",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save(`${selectedUserType}-user-data.pdf`);
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${selectedUserType}-user-data.csv`;
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${selectedUserType}-user-data.xlsx`);
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data api
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: userIds,
    };

    if (!_.isEmpty(startDateVal)) {
      data.start_date = startDateVal;
    }

    if (!_.isEmpty(endDateVal)) {
      data.end_date = endDateVal;
    }
    if (
      selectedUserType === "created_by_admin" ||
      selectedUserType === "created_by_user"
    ) {
      data.filter_by =
        selectedUserType === "created_by_user" ? "users" : "is_admin";
    }
    getApiData(BaseSetting.endpoint.allUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.allUser;

          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              "First name": val?.first_name,
              "Last name": val?.last_name,
              Email: val?.email,
              Phone: val?.phone,
              Country: val?.country,
              State: val?.state,
              "Business type": val?.type,
              Role: val?.role === "user" ? "User" : null,
              "Platform type": val?.plateform_type,
              Status:
                val?.status === "1"
                  ? "Active"
                  : val?.status === "2"
                  ? "Verified"
                  : val?.status === "3"
                  ? "Pending"
                  : val?.status === "-1"
                  ? "Remove"
                  : "-",
              "Posted post count": val?.count,
              "Reported user count": val?.reported_count,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  // premium user
  const userPremium = () => {
    setDisablePremiumBtn(true);
    setPremiumBtnLoading(true);
    if (days.trim().length === 0) {
      setDaysValidationError("Enter number of days");
      setDisablePremiumBtn(false);
      setPremiumBtnLoading(false);
    } else {
      const parsedDays = parseInt(days);
      if (isNaN(parsedDays) || parsedDays <= 0 || parsedDays > 365) {
        setDaysValidationError(
          "Invalid number of days. Enter a number between 1 and 365."
        );
        setDisablePremiumBtn(false);
        setPremiumBtnLoading(false);
      } else {
        const data = {
          days: days,
          user_id: id,
        };
        setDaysValidationError("");
        getApiData(BaseSetting.endpoint.premiumUser, "POST", data)
          .then((result) => {
            if (result?.status) {
              toast(result?.message, { type: "success" });
              setDisablePremiumBtn(false);
              setPremiumBtnLoading(false);
              setpremiumModal(false);
              setId("");
              setDays("");
            } else {
              toast(result?.message, { type: "error" });
              setDisablePremiumBtn(false);
              setPremiumBtnLoading(false);
            }
          })
          .catch((err) => {
            toast(err?.message, { type: "error" });
            setDisablePremiumBtn(false);
            setPremiumBtnLoading(false);
          });
      }
    }
  };

  // create user
  const createUser = () => {
    setBtnLoad(true);
    setDisableBtn(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      language_id: language?.id,
      country: country?.name,
      country_code: country?.country_code,
    };

    if (!_.isEmpty(phone)) {
      data.phone_code_id = countryPhoneCode?.phone_code;
      data.phone = phone;
    }

    getApiData(BaseSetting.endpoint.createUser, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setModalOpen(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setCountryPhoneCode(
            phoneCodeList?.find((v) => v?.phone_code === "+91")
          );
          setPhone("");
          setPassword("");
          setLanguage("");
          setCountry("");
          getAllUserData(selectedUserType, pageNo);
          setDisableBtn(false);
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  // get one user data
  const openEditModal = (data) => {
    const firstNameValue =
      !_.isEmpty(data.first_name) &&
      !_.isUndefined(data.first_name) &&
      !_.isNull(data.first_name)
        ? data.first_name
        : "";
    const lastNameValue =
      !_.isEmpty(data.last_name) &&
      !_.isUndefined(data.last_name) &&
      !_.isNull(data.last_name)
        ? data.last_name
        : "";
    const emailValue =
      !_.isEmpty(data.email) &&
      !_.isUndefined(data.email) &&
      !_.isNull(data.email)
        ? data.email
        : "";
    // const phoneCodeValue =
    //   _.isNumber(data.phone_code_id) &&
    //   !_.isUndefined(data.phone_code_id) &&
    //   !_.isNull(data.phone_code_id)
    //     ? data?.phone_code_id?.toString()
    //     : "";

    const phoneCodeValue =
      !_.isEmpty(data.phone_code_id) &&
      !_.isNull(data.phone_code_id) &&
      !_.isUndefined(data.phone_code_id)
        ? phoneCodeList?.find((v) => v?.phone_code === data.phone_code_id)
        : {};

    const phoneValue =
      !_.isEmpty(data.phone) &&
      !_.isUndefined(data.phone) &&
      !_.isNull(data.phone)
        ? data.phone
        : "";
    const passwordValue =
      !_.isEmpty(data.password) &&
      !_.isUndefined(data.password) &&
      !_.isNull(data.password)
        ? data.password
        : "";

    const languageValue =
      data.language_id > 0 &&
      _.isNumber(data.language_id) &&
      !_.isUndefined(data.language_id) &&
      !_.isNull(data.language_id)
        ? languageList.find((v) => v.id == data?.language_id)
        : "";
    const countryValue =
      !_.isUndefined(data.country_code) && !_.isNull(data.country_code)
        ? countryList.find((v) => v.country_code == data?.country_code)
        : {};

    setModalOpen(true);
    setEditId(data?.id);
    setFirstName(firstNameValue);
    setLastName(lastNameValue);
    setEmail(emailValue);
    setCountryPhoneCode(phoneCodeValue);
    setPhone(phoneValue);
    setPassword(passwordValue);
    setLanguage(languageValue);
    setCountry(countryValue);
  };

  //update user
  const updateUser = () => {
    setBtnLoad(true);
    setDisableBtn(true);
    const endpoint = `${BaseSetting.endpoint.updateUser}/${editId}`;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      language_id: language.id,
      country: country?.name,
      country_code: country?.country_code,
    };

    if (!_.isEmpty(phone)) {
      data.phone_code_id = countryPhoneCode;
      data.phone = phone;
    }

    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setBtnLoad(false);
          setModalOpen(false);
          getAllUserData(selectedUserType, pageNo);
          setDisableBtn(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setCountryPhoneCode(
            phoneCodeList?.find((v) => v?.phone_code === "+91")
          );
          setPhone("");
          setPassword("");
          setLanguage("");
          setCountry("");
          setEdit("");
          setEditId("");
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  // login in web using user
  const loginWithUser = (Id) => {
    getApiData(BaseSetting.endpoint.LoginWithUser, "post", {
      user_id: Id,
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          toast(result?.message, { type: "success" });
          window.location.replace(
            `${BaseSetting?.appUrl}/products?token=${result?.data}`
            // `http://localhost:3002/products?token=${result?.data}`
          );
        } else {
          toast(result?.message, { type: "error" });
          setPageLoadHistory(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoadHistory(false);
      });
  };

  //remove users
  const openModal = (id, type) => {
    setId(id);
    if (!_.isEmpty(type)) {
      setpremiumModal(true);
    } else {
      setConfirmationModal(true);
    }
  };

  const openHistoryModal = (id) => {
    setId(id);
    setPageLoadHistory(true);
    getApiData(BaseSetting.endpoint.GetLoginhistory, "post", {
      user_id: id,
      page: pageNoHistory,
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setUserLoginData(response?.loginHistory);
          setLoginPagination(response?.pagination);
          setPageNoHistory(response?.pagination?.currentPage);
          setPageLoadHistory(false);
        } else {
          setPageLoadHistory(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoadHistory(false);
      });
    setHistoryModal(true);
  };

  // remove multiple users
  const removeMultipleUsers = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.deleteMultipleUsers}`;
    const data = !_.isEmpty(userIds) ? { id: userIds } : { id: id };
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirmationModal(false);
          getAllUserData(selectedUserType, pageNo);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const userColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        usersData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Grid item>
                <Tooltip title="Edit" placement="top" arrow>
                  <Button
                    onClick={() => {
                      openEditModal(params?.row);
                      setEdit(true);
                    }}
                  >
                    <Edit style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title=" Login history" placement="top" arrow>
                  <Button onClick={() => openHistoryModal(params?.row?.id)}>
                    <History style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
              {_.isEmpty(userIds) ? (
                <Grid item>
                  <Tooltip title="Delete" placement="top" arrow>
                    <Button onClick={() => openModal(params?.row?.id)}>
                      <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                    </Button>
                  </Tooltip>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
              wrap={"wrap"}
              sm={{ backgroundColor: "red" }}
            >
              <Grid item xs={1} sm={12} md={12} lg={12} xl={12}>
                <Tooltip title="Premium" placement="top" arrow>
                  <Button
                    variant="contained"
                    onClick={() => openModal(params?.row?.id, "premium")}
                    fullWidth
                  >
                    Premium
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={1} sm={12} md={12} lg={12} xl={12}>
                <Tooltip title="Login with" placement="top" arrow>
                  <Button
                    variant="contained"
                    onClick={() => loginWithUser(params?.row?.id)}
                    fullWidth
                  >
                    Login with
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      minWidth: 300,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 130,
    },
    {
      field: "country",
      headerName: "COUNTRY",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 180,
    },
    {
      field: "state",
      headerName: "STATE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "business_type",
      headerName: "BUSINESS TYPE",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 190,
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "plateform_type",
      headerName: "PLATFORM TYPE",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      minWidth: 180,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Typography fontFamily={FontFamily.SemiBold}>
            {params?.row?.status === "1"
              ? "Active"
              : params?.row?.status === "2"
              ? "Verified"
              : params?.row?.status === "3"
              ? "Pending"
              : params?.row?.status === "-1"
              ? "Remove"
              : "-"}
          </Typography>
        );
      },
    },
    {
      field: "count",
      headerName: "POSTED POST COUNT",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 240,
      sortable: false,
      renderCell: (params) => {
        return <Typography fontFamily={FontFamily.SemiBold}>0</Typography>;
      },
    },
    {
      field: "reported_count",
      headerName: "REPORTED USER COUNT",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 240,
      sortable: false,
      renderCell: (params) => {
        return (
          // <div
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     flexDirection: "column",
          //   }}
          // >
          //   <Typography fontFamily={FontFamily.SemiBold}>
          //     {params?.row?.reported_count}
          //   </Typography>
          //   {params?.row?.reported_count > 0 && (
          //     <CButton
          //       style={{ marginTop: "5px" }}
          //       onClick={() => {
          //         navigate(`/report-users?reported_by_id=${params?.row?.id}`);
          //       }}
          //     >
          //       View user list
          //     </CButton>
          //   )}
          // </div>
          <Typography fontFamily={FontFamily.SemiBold}>0</Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const userLoginColumns = [
    {
      field: "id",
      headerName: "NUMBER",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index =
          params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1;
        const mainIndex =
          pageNoHistory === 1
            ? index
            : (pageNoHistory - 1) * loginPagination?.defaultPageSize + index;
        userLoginData.map((data, index) => {
          data.serialNumber = mainIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "loginAt",
      headerName: "LOGIN AT",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const loginAtTimestamp = new Date(params.row.loginAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(loginAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="All Users"
        children={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    onClick={() => setModalOpen(true)}
                  >
                    Create user
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <CSelect
                    style={{ marginLeft: "15px" }}
                    fullObj
                    data={userTypeData}
                    placeholder="Select user type"
                    value={userTypeData?.find(
                      (data) => data?.id === selectedUserType
                    )}
                    onSelect={(val) => {
                      setuserType(val);
                      setUserIds([]);
                      setSearchVal("");
                      setHeaderParams(1, "", "", "", "", "", [], val?.id);
                      getAllUserData(val?.id, 1, "", "", "", "", "", []);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={1}
              >
                <Button variant="contained" onClick={() => setDateModel(true)}>
                  Filter date
                </Button>
                {!_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(search) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(userIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(
                        page,
                        "",
                        "",
                        "",
                        "",
                        "",
                        [],
                        selectedUserType
                      );
                      getAllUserData(selectedUserType, page);
                      setUserIds([]);
                      setSearchVal("");
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(userIds) ? (
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setConfirmationModal(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}

                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </Grid>
            </div>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        downloadId,
                        selectedUserType
                      );
                      getAllUserData(
                        selectedUserType,
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal
                      );
                    } else {
                      setHeaderParams(
                        isPage,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId,
                        selectedUserType
                      );
                      getAllUserData(
                        selectedUserType,
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={usersData}
                  columns={userColumns}
                  disableColumnMenu
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  hideFooter={isEmpty(userIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  disableDensitySelector
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  style={{ width: "100%" }}
                  slots={{ toolbar: GridToolbar }}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rowSelectionModel={userIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setUserIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadIdString,
                        selectedUserType
                      );
                    }
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setUserIds([]);
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          [],
                          selectedUserType
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAllUserData(
                            selectedUserType,
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDateVal,
                            endDateVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>

            {!isEmpty(usersData) && isArray(usersData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId,
                      selectedUserType
                    );
                    getAllUserData(
                      selectedUserType,
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
            <CModal
              visible={confirmationModal}
              style={{ width: "30%" }}
              onClose={() => {
                setConfirmationModal(false);
              }}
              title={"Delete user"}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: FontFamily.Medium,
                          fontSize: 20,
                          textAlign: "center",
                        }}
                      >
                        Are you sure, You want to delete this user ?
                      </Typography>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <Button
                        variant="outlined"
                        onClick={() => setConfirmationModal(false)}
                        style={{ width: "100%" }}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item xs={6} padding={2}>
                      <CButton
                        variant="contained"
                        onClick={() => removeMultipleUsers()}
                        style={{ width: "100%" }}
                        loading={yesBtnLoading}
                      >
                        Yes
                      </CButton>
                    </Grid>
                  </Grid>
                </div>
              }
            />

            <CModal
              visible={premiumModal}
              style={{ width: "30%" }}
              onClose={() => {
                setpremiumModal(false);
                setDaysValidationError("");
                setDays("");
                setId("");
              }}
              title={"Premium user"}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <CInput
                        placeholder="Days"
                        errorMsg={daysValidationError}
                        value={days}
                        onChange={(val) => {
                          setDays(val);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                      <CButton
                        onClick={userPremium}
                        fullWidth
                        variant="contained"
                        disabled={disablePremiumBtn}
                        loading={premiumBtnLoading}
                      >
                        Premium
                      </CButton>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <CModal
              visible={historyModal}
              style={{ width: "30%" }}
              onClose={() => {
                setHistoryModal(false);
                setUserLoginData("");
                setLoginPagination("");
              }}
              title={"Login history"}
              children={
                <div style={{ padding: "20px" }}>
                  <Grid container>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      {pageLoadHistory ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "10vh",
                          }}
                        >
                          <CircularProgress
                            style={{ color: BaseColor.primary }}
                            size={30}
                          />
                        </div>
                      ) : (
                        <DataGrid
                          rows={userLoginData}
                          columns={userLoginColumns}
                          disableColumnMenu
                          hideFooter
                          disableDensitySelector
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                          disableSelectionOnClick
                          autoHeight={true}
                          getRowHeight={() => "auto"}
                          style={{ width: "100%" }}
                          disableColumnFilter
                          disableColumnSelector
                        />
                      )}
                    </Grid>
                    {loginPagination?.isMore && (
                      <Grid sx={{ justifyContent: "end" }} item xs={12}>
                        <Pagination
                          count={loginPagination?.totalPage}
                          defaultPage={1}
                          onChange={(e, value) => {
                            setPageNoHistory(value);
                          }}
                          sx={{ display: pageLoadHistory ? "none" : "block" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              }
            />
            <CModal
              visible={dateModel}
              style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
              onClose={() => {
                setDateModel(false);
              }}
              title={"Filter Date"}
              children={
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateRangePicker
                      disableFuture
                      slots={{
                        shortcuts: CustomRangeShortcuts,
                      }}
                      slotProps={{
                        shortcuts: {
                          items: shortcutsItems,
                        },
                        actionBar: { actions: [] },
                      }}
                      onChange={(val) => {
                        if (isNull(val[0]) && isNull(val[1])) {
                          setStartDate(null);
                          setEndDate(null);
                        } else {
                          setStartDate(formatDate(val[0]?.$d));
                          setEndDate(formatDate(val[1]?.$d));
                        }
                      }}
                      calendars={1}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    />
                  </LocalizationProvider>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => {
                      setPageLoad(true);
                      setHeaderParams(
                        1,
                        search,
                        sortVal,
                        sortByVal,
                        startDate,
                        endDate,
                        downloadId,
                        selectedUserType
                      );
                      getAllUserData(
                        selectedUserType,
                        1,
                        search,
                        sortVal,
                        sortByVal,
                        startDate,
                        endDate
                      );
                      setDateModel(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              }
            />

            <CModal
              visible={modalOpen}
              style={{ width: "50%" }}
              title={edit ? "Update User" : "Create User"}
              onClose={() => {
                handleClose();
              }}
              children={
                <Grid container item xs={8} padding={"30px"} gap={2}>
                  <Grid container gap={2} wrap="nowrap">
                    <Grid item xs={6}>
                      <CInput
                        placeholder="First name"
                        value={firstName}
                        onChange={(val) => {
                          setFirstName(val);
                          allErrorFalse();
                        }}
                        startIcon={<CIcon src={Files.svgIcons.user} />}
                        errorMsg={firstNameErrorText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CInput
                        placeholder="Last name"
                        value={lastName}
                        onChange={(val) => {
                          setLastName(val);
                          allErrorFalse();
                        }}
                        startIcon={<CIcon src={Files.svgIcons.user} />}
                        errorMsg={lastNameErrorText}
                      />
                    </Grid>
                  </Grid>
                  <Grid container gap={2} wrap="nowrap">
                    <Grid item xs={6}>
                      <CInput
                        placeholder="Email"
                        value={email}
                        onChange={(val) => {
                          setEmail(val);
                          allErrorFalse();
                        }}
                        startIcon={<CIcon src={Files.svgIcons.email} />}
                        errorMsg={emailErrorText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CInput
                        placeholder="Phone"
                        value={phone}
                        onChange={(val) => {
                          setPhone(val);
                          allErrorFalse();
                        }}
                        startIcon={
                          <>
                            <CIcon
                              src={Files.svgIcons.phone}
                              className="CInputStartIcon"
                            />

                            <CAutoComplete
                              options={phoneCodeList}
                              phoneCode
                              selectedValue={countryPhoneCode}
                              onSelect={(val) => {
                                setCountryPhoneCode(val);
                              }}
                              style={{
                                outline: "none",
                                width: "100px",
                              }}
                            />
                            <div
                              style={{
                                height: 25,
                                borderRight: `1px solid ${BaseColor.primary}`,
                              }}
                            />
                          </>
                        }
                        errorMsg={phoneErrorText}
                      />
                    </Grid>
                  </Grid>

                  {!edit ? (
                    <>
                      <Grid container gap={2} wrap="nowrap">
                        <Grid item xs={6}>
                          <CInput
                            placeholder="Password"
                            value={password}
                            onChange={(val) => {
                              setPassword(val);
                              allErrorFalse();
                            }}
                            startIcon={<CIcon src={Files.svgIcons.lock} />}
                            errorMsg={passwordErrorText}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CSelect
                            fullObj
                            data={languageList}
                            placeholder="Language"
                            value={language}
                            svgIcon={Files.svgIcons.translate}
                            onSelect={(val) => {
                              setLanguage(val);
                              allErrorFalse();
                            }}
                            errorMsg={languageErrorText}
                          />
                        </Grid>
                      </Grid>
                      <Grid container gap={2} wrap="nowrap">
                        <Grid item xs={6}>
                          <CSelect
                            country
                            fullObj
                            data={countryList}
                            placeholder="Country"
                            value={country}
                            onSelect={(val) => {
                              setCountry(val);
                              allErrorFalse();
                            }}
                            errorMsg={countryErrorText}
                          />
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container gap={2} wrap="nowrap">
                        {/* <Grid item xs={6}>
                          <CInput
                            placeholder="Phone"
                            value={phone}
                            onChange={(val) => {
                              setPhone(val);
                              allErrorFalse();
                            }}
                            startIcon={
                              <>
                                <CIcon
                                  src={Files.svgIcons.phone}
                                  className="CInputStartIcon"
                                />

                                <CAutoComplete
                                  options={phoneCodeList}
                                  phoneCode
                                  selectedValue={countryPhoneCode}
                                  onSelect={(val) => {
                                    setCountryPhoneCode(val);
                                  }}
                                  style={{
                                    outline: "none",
                                    width: "100px",
                                  }}
                                />
                                <div
                                  style={{
                                    height: 25,
                                    borderRight: `1px solid ${BaseColor.primary}`,
                                  }}
                                />
                              </>
                            }
                            errorMsg={phoneErrorText}
                          />
                        </Grid> */}
                        <Grid item xs={6}>
                          <CSelect
                            fullObj
                            data={languageList}
                            placeholder="Language"
                            value={language}
                            svgIcon={Files.svgIcons.translate}
                            onSelect={(val) => {
                              setLanguage(val);
                              allErrorFalse();
                            }}
                            errorMsg={languageErrorText}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CSelect
                            country
                            fullObj
                            data={countryList}
                            placeholder="Country"
                            value={country}
                            onSelect={(val) => {
                              setCountry(val);
                              allErrorFalse();
                            }}
                            errorMsg={countryErrorText}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    justifyContent={"space-evenly"}
                    gap={2}
                    wrap="nowrap"
                  >
                    <Grid item xs={6}>
                      <CButton
                        onClick={() =>
                          edit ? validation("update") : validation("create")
                        }
                        fullWidth
                        variant="contained"
                        disabled={disableBtn}
                        loading={btnLoad}
                      >
                        {!edit ? "Create" : "Update"}
                      </CButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                        onClick={() => handleClose()}
                        disabled={disableBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </>
        }
      />
    </Grid>
  );
};

export default AllUser;
