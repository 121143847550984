import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Box,
  Typography,
  useMediaQuery,
  Chip,
  Divider,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import _, { isArray, isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import theme, { FontFamily } from "../../Config/theme";
import CSelect from "../../Components/CSelect";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { createSearchParams, useSearchParams } from "react-router-dom";
import CModal from "../../Components/CModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";

const ReportUsers = () => {
  const searchRef = useRef();

  // user data
  const [reportUsersData, setReportUsersData] = useState([]);

  //loader and pagination
  const [pageLoad, setPageLoad] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  // responsive
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // download data and sort and searc
  const [selectFileType, setSelectFileType] = useState("");
  const [rowChange, setRowChange] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Title",
    "Reported by",
    "Reported user",
    "Description",
    "Created At",
  ]);
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // get values from search params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const reportedById = searchParams.has("reported_by_id")
    ? searchParams.get("reported_by_id")
    : "";

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";

  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  const [selIds, setselIds] = useState(downloadIdArr);
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId,
      reportedById
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  // set params in URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = [],
    reportedById
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(reportedById)) {
      params.reported_by_id = reportedById;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    getAllReportUsersData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, []);

  //all report users data
  const getAllReportUsersData = (
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    if (!_.isEmpty(reportedById)) {
      data.reported_by_id = reportedById;
    }

    getApiData(BaseSetting.endpoint.reportUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!_.isEmpty(response)) {
            setReportUsersData(response?.reportUsers);
            setPagination(response?.pagination);
          }
          toast(result?.message, { type: "success" });
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  // download data
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("ReportUserList.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "ReportUserList.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportUserList.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: selIds,
    };
    if (!_.isEmpty(reportedById)) {
      data.reported_by_id = reportedById;
    }
    getApiData(BaseSetting.endpoint.reportUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          let dataSort = [];
          setselIds([]);

          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              Id: index + 1,
              Title:
                val?.title === "1"
                  ? "Fake ID"
                  : val?.title === "2"
                  ? "Bullying"
                  : val?.title === "3"
                  ? "Spam"
                  : val?.title === "4"
                  ? "Something else"
                  : "-",
              Name: val?.reported_name,
              "Reported by": val?.full_name,
              Description: val?.description,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
          toast(result?.message, { type: "success" });
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const usersColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        reportUsersData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      minWidth: 220,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.title === "1"
              ? "Fake ID"
              : params?.row?.title === "2"
              ? "Bullying"
              : params?.row?.title === "3"
              ? "Spam"
              : params?.row?.title === "4"
              ? "Something else"
              : "-"}
          </div>
        );
      },
    },
    {
      field: "reported_name",
      headerName: "REPORTED BY",
      flex: 1,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "reported_lastname",
    //   headerName: "REPORTED BY LAST NAME",
    //   flex: 1,
    //   minWidth: 300,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "full_name",
      headerName: "REPORTED USER",
      flex: 1,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "last_name",
    //   headerName: "REPORTED USER LAST NAME",
    //   flex: 1,
    //   minWidth: 300,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 400,
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  //Date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Report Users"
        children={
          <div>
            <Grid container justifyContent={"end"} mb={"30px"}>
              <Button variant="contained" onClick={() => setDateModel(true)}>
                Filter date
              </Button>
              {!_.isEmpty(search) ||
              !_.isEmpty(search) ||
              !_.isEmpty(sortVal) ||
              !_.isEmpty(sortByVal) ||
              !_.isEmpty(downloadId) ||
              !_.isEmpty(selIds) ||
              !_.isEmpty(startDateVal) ||
              !_.isEmpty(endDateVal) ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    setHeaderParams(page, "", "", "", "", "", [], reportedById);
                    getAllReportUsersData(page);
                    setselIds([]);
                    setStartDate("");
                    setEndDate("");
                    setSearchVal("");
                    setDataLoader(true);
                    if (dataGridRef.current) {
                      dataGridRef.current.setFilterModel({
                        items: [],
                        quickFilter: "",
                      });
                    }
                  }}
                  style={{ marginLeft: "20px" }}
                >
                  Clear filters
                </Button>
              ) : null}
              <CSelect
                style={{ marginLeft: "20px" }}
                placeholder="Choose file format for download"
                value={selectFileType}
                data={downloadOptions}
                onSelect={(val) => {
                  setSelectFileType(val);
                  getDownloadData(val);
                }}
              />
            </Grid>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId,
                      reportedById
                    );
                    getAllReportUsersData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal
                    );
                  } else {
                    setHeaderParams(
                      isPage,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      [],
                      reportedById
                    );
                    getAllReportUsersData(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={reportUsersData}
                columns={usersColumns}
                disableColumnMenu
                hideFooter={isEmpty(selIds)}
                hideFooterPagination
                loading={dataLoader}
                sortingMode="server"
                apiRef={getDataGridApi}
                disableDensitySelector
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={selIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  const downloadIdString = id.join(",");
                  setselIds(id);
                  if (rowChange === true) {
                    setHeaderParams(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString,
                      reportedById
                    );
                  }
                }}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId,
                        reportedById
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getAllReportUsersData(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      }, 800);
                    },
                  },
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall": {
                    display: "none",
                    backgroundColor: "red",
                  },
                  "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                    display: "none",
                  },
                  "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "#674188",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                    {
                      width: "1px",
                      height: "4px",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                    {
                      background: "#F7EFE5",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "rgba(103, 65, 136, 0.5)",
                      borderRadius: "10px",
                    },
                }}
              />
            )}
            {!isEmpty(reportUsersData) && isArray(reportUsersData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId,
                      reportedById
                    );
                    getAllReportUsersData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </div>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId,
                    reportedById
                  );
                  getAllReportUsersData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default ReportUsers;
