import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import { FontFamily } from "../../Config/theme";
import CInput from "../../Components/CInput";
import { Delete, Edit } from "@mui/icons-material";
import CButton from "../../Components/CButton";
import { createSearchParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

const SystemParameters = () => {
  const searchRef = useRef();
  const [pageLoad, setPageLoad] = useState(false);
  const [systemParaData, setSystemParaData] = useState([]);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, setEditId] = useState("");
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  // get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [delIds, setDelIds] = useState(downloadIdArr);
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, downloadId);
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params in URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  //form
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");

  //validation
  const [nameErrorText, setNameErrorText] = useState("");
  const [valueErrorText, setValueErrorText] = useState("");
  const [descriptionErrorText, setDescriptionErrorText] = useState("");

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setPageLoad(true);
    getAllSystemParametersData(page, search, sortVal, sortByVal);
  }, []);

  const validation = () => {
    if (name.trim() === "") {
      allErrorFalse();
      setNameErrorText("Name is required");
    } else if (!/^[a-zA-Z_\s]+$/.test(name)) {
      allErrorFalse();
      setNameErrorText("Only characters are allowed");
    } else if (value.trim() === "") {
      allErrorFalse();
      setValueErrorText("Value is required");
    } else if (description.trim() === "") {
      allErrorFalse();
      setDescriptionErrorText("Description is required");
    } else {
      allErrorFalse();
      createAndUpdateSystemParameter(edit);
    }
  };

  const allErrorFalse = () => {
    setNameErrorText("");
    setValueErrorText("");
    setDescriptionErrorText("");
  };

  const openModal = (data) => {
    setOpen(true);
    setEditId(data?.id);
    setName(data?.name);
    setValue(data?.value);
    setDescription(data?.description);
  };

  //all system parameters data
  const getAllSystemParametersData = (page, search, sort, sortBy) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    getApiData(BaseSetting.endpoint.systemParametersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            setSystemParaData(response?.system_parameters);
            setPagination(response?.pagination);
          }
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  //create system parameters
  const createAndUpdateSystemParameter = (isUpdate) => {
    if (!isUpdate) {
      setEdit(false);
    }

    setBtnLoad(true);
    setDisableBtn(true);

    const endpoint = isUpdate
      ? BaseSetting.endpoint.systemParametersUpdate
      : BaseSetting.endpoint.systemParametersCreate;
    let data = {
      value: value,
      description: description,
      is_editable_for_client: 1,
    };
    if (!isUpdate) {
      data.name = name;
    }
    if (isUpdate) {
      data.id = editId;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          getAllSystemParametersData(pageNo);
          setBtnLoad(false);
          setOpen(false);
          handleClose();
          setEdit(false);
          setDisableBtn(false);
        } else {
          toast(result?.message, { type: "error" });
          setBtnLoad(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setBtnLoad(false);
        setDisableBtn(false);
      });
  };

  //delete parameter
  const deleteSystemParameter = () => {
    setYesBtnLoading(true);
    const endpoint = `${BaseSetting.endpoint.systemParametersDelete}`;
    getApiData(endpoint, "post", { id: !_.isEmpty(delIds) ? delIds : deleteId })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setDelIds([]);
          setDeleteId("");
          getAllSystemParametersData(pageNo);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  const paraColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        systemParaData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTIONS",
      width: 165,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Tooltip title="Edit" placement="top" arrow>
                <Button
                  onClick={() => {
                    openModal(params?.row);
                    setEdit(true);
                  }}
                >
                  <Edit style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            {_.isEmpty(delIds) ? (
              <Grid item>
                <Tooltip title="Delete" placement="top" arrow>
                  <Button
                    onClick={() => {
                      setConfirm(true);
                      setDeleteId(params?.row?.id);
                    }}
                  >
                    <Delete style={{ color: BaseColor.red, fontSize: 20 }} />
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
    {
      field: "name",
      headerName: "NAME",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 900,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "VALUE",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setName("");
    setValue("");
    setDescription("");
    allErrorFalse();
  };

  return (
    <Grid container>
      <MiniDrawer
        header="System Parameters"
        children={
          <div>
            <Grid justifyContent={"space-between"} container>
              <Button variant="contained" onClick={() => setOpen(true)}>
                Create system parameter
              </Button>
              <div style={{ display: "flex" }}>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", []);
                      setDataLoader(true);
                      setSearchVal("");
                      getAllSystemParametersData(page);
                      setDelIds([]);
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                {!_.isEmpty(delIds) ? (
                  <Button
                    variant="outlined"
                    style={{
                      color: "red",
                      borderColor: "red",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        downloadId
                      );
                      getAllSystemParametersData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort
                      );
                    } else {
                      setHeaderParams(isPage, search, "", "", downloadId);
                      getAllSystemParametersData(page, search);
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  width={"100%"}
                  rows={systemParaData}
                  columns={paraColumns}
                  disableColumnMenu
                  hideFooter={isEmpty(delIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  disableDensitySelector
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  disableRowSelectionOnClick
                  checkboxSelection
                  rowSelectionModel={delIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setDelIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        downloadIdString
                      );
                    }
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAllSystemParametersData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(systemParaData) && isArray(systemParaData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      downloadId
                    );
                    getAllSystemParametersData(
                      value,
                      search,
                      sortVal,
                      sortByVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </div>
        }
      />
      <CModal
        visible={open}
        style={{ width: "60%" }}
        onClose={() => {
          handleClose();
          allErrorFalse();
          setEditId("");
          setEdit(false);
        }}
        title={edit ? "Update System Parameter" : "Create System Parameter"}
        children={
          <>
            <Grid container padding={"30px"} gap={2}>
              <Grid item xs={12} md={5.8} lg={5.85}>
                <CInput
                  placeholder="Name"
                  value={name}
                  readOnly={edit}
                  onChange={(val) => {
                    setName(val);
                    setNameErrorText("");
                  }}
                  errorMsg={nameErrorText}
                />
              </Grid>
              <Grid item xs={12} md={5.8} lg={5.85}>
                <CInput
                  placeholder="Value"
                  value={value}
                  onChange={(val) => {
                    setValue(val);
                    setValueErrorText("");
                  }}
                  errorMsg={valueErrorText}
                />
              </Grid>
              <Grid item xs={12} md={11.8} lg={11.85}>
                <CInput
                  placeholder="Description"
                  value={description}
                  multiline
                  onChange={(val) => {
                    setDescription(val);
                    setDescriptionErrorText("");
                  }}
                  errorMsg={descriptionErrorText}
                  rows={6}
                  maxRows={6}
                />
              </Grid>
              <Grid
                container
                justifyContent={"space-evenly"}
                gap={2}
                wrap="nowrap"
              >
                <Grid item xs={6}>
                  <CButton
                    onClick={() => {
                      validation();
                    }}
                    fullWidth
                    variant="contained"
                    disabled={disableBtn}
                    loading={btnLoad}
                  >
                    {!edit ? "Create" : "Update"}
                  </CButton>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      handleClose();
                      allErrorFalse();
                      setEditId("");
                      setEdit(false);
                    }}
                    disabled={disableBtn}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setConfirm(false);
          setDeleteId("");
        }}
        title={"Delete system parameter"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to delete this parameter ?
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={"center"}
                gap={2}
                wrap="nowrap"
                marginTop={"10px"}
              >
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                      setDeleteId("");
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => deleteSystemParameter()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
    </Grid>
  );
};

export default SystemParameters;
