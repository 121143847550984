import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import _, { isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import theme, { FontFamily } from "../../Config/theme";
import { createSearchParams, useSearchParams } from "react-router-dom";
import CModal from "../../Components/CModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import CSelect from "../../Components/CSelect";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CButton from "../../Components/CButton";

const RatingList = () => {
  //ref
  const searchRef = useRef();
  const dataGridRef = useRef();

  //rating data
  const [ratingTemplateData, setratingTemplateData] = useState([]);

  //pagination and loader
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Name",
    "User id",
    "Email",
    "Rating",
    "User count",
    "Created at",
  ]);
  const [searchParams, setSearchParams] = useSearchParams();

  //date filter
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // user rating list modal
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [userRatingData, setUserRatingData] = useState([]);
  const [ratingModalLoader, setRatingModalLoader] = useState(false);
  const [ratingDataPage, setRatingDataPage] = useState(1);
  const [userId, setUserId] = useState("");

  // get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [selIds, setselIds] = useState(downloadIdArr);
  const [rowChange, setRowChange] = useState(false);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setPageLoad(true);
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  //set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  useEffect(() => {
    getAllratingTemplateApiCall(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal
    );
  }, []);

  //all rating template data api
  const getAllratingTemplateApiCall = (
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date
  ) => {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }

    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }
    getApiData(BaseSetting.endpoint.getRatingList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setratingTemplateData(response?.data);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  // download data in different formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("Rating-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Rating-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Rating-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // download data
  function getDownloadData(type) {
    let data = {
      download: true,
      download_id: selIds,
      type: "admin",
    };
    getApiData(BaseSetting.endpoint.getRatingList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.data;
          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              Name: val.user_name,
              "User id": val?.user_id,
              Email: val?.email,
              Rating: val.avg_rating,
              "User count": val?.count,
              "Created At": formattedLoginAt,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const ratingTemplateColumn = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        ratingTemplateData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "user_name",
      headerName: "NAME",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user_id",
      headerName: "USER ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "avg_rating",
      headerName: "AVERAGE RATING",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const numericValue = parseFloat(params?.row?.avg_rating);
        let formattedValue = numericValue.toFixed(1);

        if (formattedValue.includes(".")) {
          formattedValue = formattedValue.replace(/\.?0*$/, "");
        }
        return <span>{formattedValue}</span>;
      },
    },
    {
      field: "count",
      headerName: "USER COUNT",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const userCount = params?.row?.count;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span>{userCount}</span>
            <CButton
              style={{ marginTop: "10px" }}
              onClick={() => {
                setUserId(params?.row?.count);
                getUserRating(params?.row?.user_id, 1);
                setOpenRatingModal(true);
              }}
            >
              Users list
            </CButton>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  //get user rating data api
  async function getUserRating(id, page) {
    setRatingModalLoader(true);
    try {
      const response = await getApiData(
        BaseSetting.endpoint.getUserRating,
        "POST",
        { user_id: id, page: page }
      );
      if (response?.status) {
        setUserRatingData(response?.data?.Data);
        setRatingDataPage(response?.data?.pagination);
        setRatingModalLoader(false);
      } else {
        setRatingModalLoader(false);
      }
    } catch (error) {
      setRatingModalLoader(false);
    }
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  // date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Rating List"
        children={
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setDateModel(true);
                  }}
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "", []);
                      getAllratingTemplateApiCall(page);
                      setSearchVal("");
                      setselIds([]);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}

                <CSelect
                  style={{ marginLeft: "20px" }}
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </Grid>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllratingTemplateApiCall(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal
                      );
                    } else {
                      setHeaderParams(
                        isPage,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllratingTemplateApiCall(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={ratingTemplateData}
                  columns={ratingTemplateColumn}
                  disableColumnMenu
                  disableDensitySelector
                  hideFooter={isEmpty(selIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  autoHeight={true}
                  checkboxSelection
                  rowSelectionModel={selIds}
                  keepNonExistentRowsSelected
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  onRowSelectionModelChange={(id) => {
                    const downloadIdString = id.join(",");
                    setselIds(id);
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDate,
                        endDate,
                        downloadIdString
                      );
                    }
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getAllratingTemplateApiCall(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDateVal,
                            endDateVal
                          );
                        }, 800);
                      },
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(ratingTemplateData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    getAllratingTemplateApiCall(value);
                    setPageNo(value);
                    setPageLoad(true);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getAllratingTemplateApiCall(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  getAllratingTemplateApiCall(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
      <CModal
        visible={openRatingModal}
        onClose={() => setOpenRatingModal(false)}
        style={{
          width: sm ? "80vw" : md ? "65vw" : lg ? "50vw" : "35%",
        }}
        title={"Users List"}
      >
        <div style={{ padding: "20px", position: "relative" }}>
          <Grid container justifyContent={"center"} rowGap={2}>
            <Grid item xs={12} textAlign={"center"}>
              <Typography
                style={{
                  fontFamily: FontFamily.SemiBold,
                  fontWeight: "900",
                  fontSize: sm ? "20px" : "25px",
                }}
              >
                Ratings
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {ratingModalLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "20vh",
                    }}
                  >
                    <CircularProgress
                      style={{ color: BaseColor.primary }}
                      size={30}
                    />
                  </div>
                ) : (
                  userRatingData?.map((item) => (
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: BaseColor.offWhite,
                        marginBottom: 10,
                        borderRadius: "5px",
                      }}
                      padding={"10px"}
                    >
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: md ? 12 : 14,
                              fontFamily: FontFamily.SemiBold,
                              fontWeight: "900",
                            }}
                          >
                            {item?.first_name} {item?.last_name}
                          </span>
                        </div>
                        <div
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontWeight: "900",
                            width: "40%",
                          }}
                        >
                          <Rating
                            name="half-rating-read"
                            value={item?.rate}
                            readOnly
                            sx={{ color: BaseColor.primary }}
                            size={sm ? "small" : "large"}
                          />
                        </div>
                        <div
                          style={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: FontFamily.Medium,
                                color: BaseColor.textColor,
                                marginLeft: "10px",
                              }}
                            >
                              {item.country}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))
                )}
              </Grid>
              {ratingDataPage?.totalPage > 1 && (
                <Pagination
                  variant="outlined"
                  count={userRatingData?.pagination?.totalPage}
                  boundaryCount={1}
                  siblingCount={0}
                  defaultPage={ratingDataPage}
                  onChange={(v, e) => {
                    setRatingDataPage(e);
                    getUserRating(userId, e);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CModal>
    </Grid>
  );
};

export default RatingList;
