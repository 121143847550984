import { React, useState } from "react";
import { Grid } from "@mui/material";
import styles from "./styles";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import { FontFamily } from "../../Config/theme";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authActions from "../../Redux/Reducers/Auth/actions";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAccessToken, setUserData } = authActions;

  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const [forgot, setForgot] = useState(false);

  //state for form
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [tempAuthtoken, setTempAuthtoken] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCodeId, setPhoneCodeId] = useState("");

  //loader
  const [loginBtnLoading, setLoginBtnLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  //Error messages
  const [emailOrMobileErrorText, setEmailOrMobileErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [verifyOTPErrorText, setVerifyOTPErrorText] = useState("");

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       validation();
  //       // event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  const validation = () => {
    if (emailOrMobile.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailOrMobileErrorText("Email or mobile is required");
    } else if (
      emailOrMobile.charAt(0).match(/^[A-Za-z]+$/) &&
      !emailOrMobile.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)
    ) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailOrMobileErrorText("Enter valid email address");
    } else if (emailOrMobile.match(/^[6-9][0-9]{9}$/)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailOrMobileErrorText("Enter valid mobile number");
    } else if (password.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setPasswordErrorText("Password is required");
    } else {
      allErrorFalse();
      LoginApiCall();
    }
  };

  const verifyOTPValidation = () => {
    if (otp.trim() === "") {
      allErrorFalse();
      setVerifyOTPErrorText("OTP is required");
    } else {
      allErrorFalse();
      verifyOTP();
    }
  };

  async function LoginApiCall() {
    setLoginBtnLoading(true);
    try {
      const response = await getApiData(
        BaseSetting.endpoint.adminlogin,
        "POST",
        { email: emailOrMobile, password: password }
      );
      if (response?.status) {
        setTempAuthtoken(response?.data?.authtoken);
        setLoginBtnLoading(false);
        if (response?.data?.isTwoFA) {
          setIsVerifyOTP(true);
        } else {
          setIsVerifyOTP(false);
          dispatch(setAccessToken(response?.data?.token));
          navigate("/dashboard");
        }

        setToken(response?.data?.token);
        setPhone(response?.data?.phone);
        setPhoneCodeId(response?.data?.phone_code_id);
        dispatch(setUserData(response?.data?.userData));
        toast(response?.message, { type: "success" });
      } else {
        toast(response?.message, { type: "error" });
        setLoginBtnLoading(false);
      }
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setLoginBtnLoading(false);
    }
  }

  const ForgotValidation = () => {
    if (email.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailErrorText("Email is required");
    } else if (email.charAt(0).match(/^[A-Za-z]+$/)) {
      if (email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/))
        window.scrollTo(0, 0);
      allErrorFalse();
      setEmailErrorText("Enter valid email address");
    } else {
      setEmailErrorText("");
    }
  };

  const verifyOTP = () => {
    setVerifyLoading(true);

    const data = {
      phone: phone,
      otp: otp,
      token: token,
      type: "login",
    };
    const header = {
      "Content-Type": "application/json",
      authorization: tempAuthtoken ? `Bearer ${tempAuthtoken}` : "",
    };

    const endPoint = BaseSetting.endpoint.verifyOTP;

    getApiData(endPoint, "POST", data, header)
      .then((result) => {
        if (result?.status) {
          setToken("");
          setOtp("");
          setVerifyLoading(false);
          setIsVerifyOTP(false);
          dispatch(setAccessToken(tempAuthtoken));
          navigate("/dashboard");
          setVerifyLoading(false);
        } else {
          toast(result?.message, { type: "error" });
          setVerifyLoading(false);
        }
      })
      .catch((error) => {
        toast(error.toString(), { type: "error" });
        setVerifyLoading(false);
      });
  };

  const submitForm = (event) => {
    if (event.keyCode === 13) {
      validation();
    }
  };

  const ForgotPasswordFunction = () => {
    return (
      <div style={{ padding: "20px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"left"}>
            <span style={{ fontFamily: FontFamily.Medium, fontSize: "16px" }}>
              Forgot Password?
            </span>
            <br />
            <span style={{ fontFamily: FontFamily.Medium, fontSize: "14px" }}>
              Enter the email address associated with your account
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            marginTop={"20px"}
            marginBottom={"20px"}
          >
            <CInput
              placeholder="Email"
              value={email}
              onChange={(val) => setEmail(val)}
              startIcon={
                <CIcon src={Files.svgIcons.email} className="CInputStartIcon" />
              }
              errorMsg={emailErrorText}
            />
          </Grid>
          {/* <Grid item xs={12}>
                <CInput
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(val) => setPassword(val)}
                  startIcon={<MdLockOutline className="CInputStartIcon" />}
                  errorMsg={passwordErrorText}
                  endIcon={
                    !showPassword ? (
                      <AiOutlineEye
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(true)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(false)}
                      />
                    )
                  }
                />
              </Grid> */}

          <Grid item xs={12}>
            <CButton
              style={{ marginTop: "10px" }}
              onClick={() => {
                ForgotValidation();
              }}
              // loading={loading}
              variant="contained"
            >
              Continue
            </CButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const LoginFunction = () => {
    return (
      <div style={{ padding: "20px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            textAlign={"center"}
          >
            <img
              src={Files?.images?.colorLogo}
              style={{ height: 100, width: 300, objectFit: "contain" }}
            />
            <br />
            <span style={{ fontFamily: FontFamily.Bold, fontSize: "30px" }}>
              {!isVerifyOTP ? "Log in to your account" : "Verify OTP"}
            </span>
          </Grid>
          {!isVerifyOTP ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                marginTop={"20px"}
                marginBottom={"20px"}
              >
                <CInput
                  placeholder="Email or mobile number"
                  value={emailOrMobile}
                  onChange={(val) => {
                    setEmailOrMobile(val);
                    allErrorFalse();
                  }}
                  startIcon={
                    <CIcon
                      src={Files.svgIcons.email}
                      className="CInputStartIcon"
                    />
                  }
                  errorMsg={emailOrMobileErrorText}
                />
              </Grid>
              <Grid item xs={12}>
                <CInput
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onKeyDown={(e) => submitForm(e)}
                  onChange={(val) => {
                    setPassword(val);
                    allErrorFalse();
                  }}
                  startIcon={
                    <CIcon
                      src={Files.svgIcons.lock}
                      className="CInputStartIcon"
                    />
                  }
                  errorMsg={passwordErrorText}
                  endIcon={
                    !showPassword ? (
                      <AiOutlineEye
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: "pointer" }}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <CButton
                  onClick={() => validation()}
                  loading={loginBtnLoading}
                  variant="contained"
                >
                  Log in
                </CButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <span style={{ margin: "20px 0" }}>
                {"OTP sent to your +" + phoneCodeId + " " + phone + " number."}
              </span>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(val) => {
                    setOtp(val);
                    allErrorFalse();
                  }}
                  errorMsg={verifyOTPErrorText}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <CButton
                  onClick={() => verifyOTPValidation()}
                  loading={verifyLoading}
                  variant="contained"
                >
                  Verify OTP
                </CButton>
              </Grid>
            </Grid>
          )}
          {/* 
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            style={{ display: "flex", paddingTop: 10 }}
          >
            <div
              onClick={() => {
                setForgot(!forgot);
              }}
            >
              <span className={classes.forgotText}>Forgot Password?</span>
            </div>
            </Grid> */}
        </Grid>
      </div>
    );
  };
  const allErrorFalse = () => {
    setEmailOrMobileErrorText("");
    setPasswordErrorText("");
    setVerifyOTPErrorText("");
  };

  return (
    <Grid container justifyContent="center" sx={{}}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.mainContainer}
      />

      <div className={classes.mainBoxContainer}>
        {forgot ? ForgotPasswordFunction() : LoginFunction()}
      </div>
    </Grid>
  );
};
export default Login;
