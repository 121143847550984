import { combineReducers } from "redux";
import auth from "./Auth/reducer";
import language from "./Language/reducer";

const rootReducer = combineReducers({
  auth,
  language,
});

export default rootReducer;
