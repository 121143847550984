import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../../../Components/Drawer";
import { FontFamily } from "../../../Config/theme";
import { useAsyncError, useLocation, useNavigate } from "react-router-dom";
import CInput from "../../../Components/CInput";
import { Editor } from "@tinymce/tinymce-react";
import CButton from "../../../Components/CButton";
import { getApiData } from "../../../Apis/apiHelper";
import BaseSetting from "../../../Apis/setting";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaseColor from "../../../Config/Color";
import _ from "lodash";

const CreateUpdateCMS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = location?.state?.isEdit;
  const editData = location?.state?.editData;

  const editorRefApp = useRef(null);
  const editorRefHtml = useRef(null);

  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [appEditorText, setAppEditorText] = useState("");
  const [htmlEditorText, setHtmlEditorText] = useState("");

  //validation
  const [titleErrorText, setTitleErrorText] = useState("");
  const [appBodyErrorText, setAppBodyErrorText] = useState("");
  const [HtmlBodyErrorText, setHtmlBodyErrorText] = useState("");

  useEffect(() => {
    if (isEdit && !_.isEmpty(editData)) {
      setTitle(editData?.title);
      setMetaDescription(editData?.meta_description);
      setMetaTitle(editData?.meta_title);
      setMetaKeyword(editData?.meta_keyword);
      setAppEditorText(editData?.app_body);
      setHtmlEditorText(editData?.app_body);
    }
  }, [editData, isEdit]);

  const allErrorFalse = () => {
    setTitleErrorText("");
    setAppBodyErrorText("");
    setHtmlBodyErrorText("");
  };

  const validation = (type) => {
    const app_body = editorRefApp.current.getContent();
    const html_body = editorRefHtml.current.getContent();
    if (title.trim() === "") {
      allErrorFalse();
      setTitleErrorText("Title is required");
      window.scrollTo(0, 0);
    } else if (app_body.trim() === "") {
      allErrorFalse();
      setAppBodyErrorText("App body is Required");
    } else if (html_body.trim() === "") {
      allErrorFalse();
      setHtmlBodyErrorText("Html body is Required");
    } else {
      allErrorFalse();
      createAndUpdateCMS(type === "update");
    }
  };

  const createAndUpdateCMS = (isUpdate) => {
    setSaveBtnLoading(true);
    setDisableBtn(true);

    const app_body = editorRefApp.current.getContent();
    const html_body = editorRefHtml.current.getContent();
    const endpoint = isUpdate
      ? BaseSetting.endpoint.updateCMS
      : BaseSetting.endpoint.createCMS;

    let data = {
      title: title,
      html_body: html_body,
      app_body: app_body,
      meta_title: metaTitle,
      meta_keyword: metaKeyword,
      meta_description: metaKeyword,
    };

    if (isUpdate) {
      data.id = editData?.id;
    }
    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setSaveBtnLoading(false);
          setDisableBtn(false);
          navigate("/cms");
        } else {
          toast(result?.message, { type: "error" });
          setSaveBtnLoading(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setSaveBtnLoading(false);
        setDisableBtn(false);
      });
  };

  return (
    <div>
      <Grid container>
        <MiniDrawer
          header={isEdit ? "Update CMS" : "Create CMS"}
          children={
            <div>
              <Grid container>
                <Button variant="contained" onClick={() => navigate("/cms")}>
                  <ArrowBackIcon /> Back
                </Button>
              </Grid>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      marginBottom: 15,
                      fontFamily: FontFamily.SemiBold,
                      fontSize: 20,
                    }}
                  >
                    {isEdit ? "Update CMS" : "Create CMS"}
                  </Typography>
                </Grid>
                <Grid item xs={6} paddingRight={"10px"}>
                  <CInput
                    placeholder="Title name"
                    onChange={(val) => {
                      setTitle(val);
                    }}
                    value={title}
                    style={{ width: "100%" }}
                    errorMsg={titleErrorText}
                  />
                </Grid>
                <Grid item xs={6} paddingLeft={"10px"}>
                  <CInput
                    placeholder="Meta title"
                    onChange={(val) => {
                      setMetaTitle(val);
                    }}
                    value={metaTitle}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6} paddingRight={"10px"}>
                  <Typography
                    style={{
                      marginBottom: 15,
                      fontFamily: FontFamily.SemiBold,
                      fontSize: 15,
                    }}
                  >
                    APP Body
                  </Typography>
                  {/* <Editor
                    apiKey={BaseSetting?.tinymceEditorApiKey}
                    onInit={(evt, editor) => (editorRefApp.current = editor)}
                    initialValue={isEdit ? editData?.app_body : ""}
                    onChange={() => setAppBodyErrorText("")}
                    init={{
                      height: 400,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  /> */}
                  <Editor
                    apiKey={BaseSetting?.tinymceEditorApiKey}
                    onInit={(evt, editor) => (editorRefApp.current = editor)}
                    plugins={"image code"}
                    value={appEditorText}
                    init={{
                      height: 500,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount image code",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent image | " +
                        "removeformat | image | code",
                      images_upload_url: `${
                        BaseSetting?.serverUrl +
                        BaseSetting?.endpoint?.imageUpload
                      }`,
                    }}
                    onEditorChange={(content, editor) => {
                      setAppEditorText(content);
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                      marginTop: "10px",
                    }}
                  >
                    {appBodyErrorText}
                  </Typography>
                </Grid>
                <Grid item xs={6} paddingLeft={"10px"}>
                  <Typography
                    style={{
                      marginBottom: 15,
                      fontFamily: FontFamily.SemiBold,
                      fontSize: 15,
                    }}
                  >
                    HTML Body
                  </Typography>
                  {/* <Editor
                    apiKey={BaseSetting?.tinymceEditorApiKey}
                    onInit={(evt, editor) => (editorRefHtml.current = editor)}
                    initialValue={isEdit ? editData?.html_body : ""}
                    init={{
                      height: 400,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  /> */}
                  <Editor
                    apiKey={BaseSetting?.tinymceEditorApiKey}
                    onInit={(evt, editor) => (editorRefHtml.current = editor)}
                    plugins={"image code"}
                    value={htmlEditorText}
                    init={{
                      height: 500,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount image code",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent image | " +
                        "removeformat | image | code",
                      images_upload_url: `${
                        BaseSetting?.serverUrl +
                        BaseSetting?.endpoint?.imageUpload
                      }`,
                    }}
                    onEditorChange={(content, editor) => {
                      setHtmlEditorText(content);
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                      marginTop: "10px",
                    }}
                  >
                    {HtmlBodyErrorText}
                  </Typography>
                </Grid>

                <Grid item xs={6} paddingRight={"10px"}>
                  <CInput
                    placeholder="Meta keyword"
                    onChange={(val) => {
                      setMetaKeyword(val);
                    }}
                    value={metaKeyword}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6} paddingLeft={"10px"}>
                  <CInput
                    placeholder="Meta description"
                    onChange={(val) => {
                      setMetaDescription(val);
                    }}
                    value={metaDescription}
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    maxRows={4}
                  />
                </Grid>

                <Grid container marginTop={"20px"}>
                  <Grid item xs={12} sm={12} md={6}>
                    <CButton
                      variant="contained"
                      onClick={() => {
                        validation(isEdit ? "update" : "create");
                      }}
                      style={{ width: "100%" }}
                      disabled={disableBtn}
                      loading={saveBtnLoading}
                    >
                      Save
                    </CButton>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          }
        />
      </Grid>
    </div>
  );
};

export default CreateUpdateCMS;
