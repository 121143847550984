import types from "./actions";

const initialState = {
  accessToken: "",
  userData: {},
  notificationData:false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: actions.accessToken,
      };
    case types.SET_USER_DATA:
      return { ...state, userData: actions.userData };

    case types.SET_UNREAD_NOTIFICATIONS:
      return { ...state, notificationData: actions.notificationData };

    default:
      return state;
  }
}
