import { makeStyles } from "@mui/styles";
import { color, fontFamily, fontSize } from "@mui/system";
import { isMobile } from "react-device-detect";
import BaseColor from "../../../Config/Color";
import { FontFamily } from "../../../Config/theme";

const styles = makeStyles({
  input: {
    border: `1px solid ${BaseColor.primary}`,
    fontFamily: FontFamily.Medium,
    color: "#9B9B9B",
  },
  headerText: {
    cursor: "pointer !important",
    fontFamily: `${FontFamily.SemiBold} !important`,
    fontSize: isMobile ? "20px !important" : "26px !important",
    "@media (max-width: 360px)": {
      fontSize: "20px !important",
    },
  },
  subContainer: {
    paddingTop: 40,
    paddingBottom: 40,
    justifyContent: "center",
  },
  smallImgs: {
    height: 100,
    width: 100,
    objectFit: "cover",
    borderRadius: 10,
    boxShadow: `1px 2px 3px ${BaseColor.textColor}`,
    cursor: "pointer",
    "@media (max-width:1500px)": {
      width: 75,
      height: 75,
    },
  },

  startView: {
    display: "flex",
    flexDirection: "column",
  },
  titleTxt: {
    fontSize: 18,
    fontFamily: `${FontFamily.Bold} !important`,
    marginBottom: "5px !important",
  },
  text: {
    fontFamily: `${FontFamily.Medium} !important`,
  },

  txtIcon: {
    paddingTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonView: {
    paddingTop: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  priceTxt: {
    fontSize: 20,
    fontFamily: FontFamily.RobotoBold,
    color: BaseColor.primary,
  },
  icons: {
    background: BaseColor.primary,
    borderRadius: 2,
    padding: 2,
    color: BaseColor.whiteColor,
    marginRight: 5,
    //  margin: 5,
  },
  iconStyle: {
    // padding: 2,
    marginRight: 7,
  },
  shareIcon: {
    background: BaseColor.primary,
    borderRadius: 5,
    padding: 10,
    color: BaseColor.whiteColor,
  },
  addCartBtn: {
    width: "80%",
  },
  countryImg: {
    height: 20,
    width: 25,
    justifyContent: "center",
  },
  endView: {
    paddingTop: 10,
    display: "flex",
  },
  profileView: {
    paddingRight: 10,
  },
  profileTxtView: {
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  socailIcon: {
    height: 10,
    width: 10,
    alignItems: "center",
  },
  profileImg: {
    height: 90,
    width: 90,
    justifyContent: "center",
    borderRadius: 50,
    border: `1px solid ${BaseColor.primary}`,
    cursor: "pointer",
  },
  userHeading: {
    marginBottom: 20,
    fontFamily: FontFamily.SemiBold,
    fontSize: 20,
  },
  userName: {
    fontSize: 16,
    fontFamily: FontFamily.Bold,
  },
  address: {
    fontSize: "13px !important",
    fontFamily: FontFamily.Medium,
  },
  desTiltle: {
    fontFamily: FontFamily.SemiBold,
    fontSize: 18,
    color: BaseColor.blackTxt,
  },
  descriptionTxt: {
    fontFamily: `${FontFamily.Medium} !important`,
    fontSize: 16,
    color: BaseColor.textGray,
  },
  mainView: {
    display: "flex",
    paddingBottom: 30,
    flexWrap: "wrap",

    boxSizing: "border-box",
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  cateTxt: {
    fontFamily: FontFamily.SemiBold,
    fontSize: 16,
  },
  linkTxt: {
    fontFamily: FontFamily.SemiBold,
    fontSize: 14,
    color: BaseColor.primary,
    textDecorationLine: "underline",
    textDecorationColor: BaseColor.primary,
  },
  desTxt: {
    fontFamily: FontFamily.SemiBold,
    fontSize: 14,
    color: BaseColor.textGray,
  },
  listStyl: {
    background: BaseColor.offWhite,
    padding: 20,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    margin: 5,
    justifyContent: "space-between",
  },
  infoIcon: {
    fontSize: "18px !important",
    color: BaseColor.primary,
    cursor: "pointer",
  },
  scrollBar: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.primary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },

  modlprofileImg: {
    height: 100,
    width: 100,
    justifyContent: "center",
    borderRadius: 50,
  },

  //Modal View
  modalView: {
    padding: 20,
    margin: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  closeIcon: {
    position: "absolute",

    fontFamily: FontFamily.Bold,
    fontSize: 22,
    color: BaseColor.primary,
  },
  modalHeading: {
    fontFamily: FontFamily.SemiBold,
    fontSize: 20,
    textAlign: "center",
  },
  secModalView: {
    marginTop: 20,
  },
  modalSmallTxt: {
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    color: BaseColor.lightPurple,
  },
  headingTxt: {
    paddingTop: 20,
    textAlign: "center",
    fontFamily: FontFamily.Regular,
    fontSize: 16,
    color: BaseColor.textGray,
  },
  car: {
    "& .css-1g04gm2-MuiButtonBase-root-MuiIconButton-root": {
      backgroundColor: "transparent",
      color: BaseColor.primary,
      border: `1px solid ${BaseColor.primary}`,
    },
    "& .css-1abc02a:hover button": {
      backgroundColor: BaseColor.primary,
      color: BaseColor.white,
    },
    "& .css-hn784z:hover button": {
      backgroundColor: BaseColor.primary,
      color: BaseColor.white,
    },
  },
  carImg: {
    height: isMobile ? 200 : 400,
    width: "75%",
    objectFit: "contain",
    borderRadius: 10,
  },
  fontSize:{
    fontSize: 15,
    "@media (max-width:1200px)": {
      fontSize: 12,
    },
    "@media (max-width:900px)": {
      fontSize: 12,
    },
  }
});
export default styles;
