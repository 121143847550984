import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { isArray, isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import theme, { FontFamily } from "../../Config/theme";
import _ from "lodash";
import CModal from "../../Components/CModal";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import CAutocomplete from "../../Components/CAutoComplete/CAutoComplete";
import { createSearchParams, useSearchParams } from "react-router-dom";
import CAutoComplete from "../../Components/CAutoComplete/CAutoComplete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import styles from "./styles";
import { shortcutsItems } from "../../Config/staticData";
import moment from "moment";

const RedirectPageData = [
  {
    id: 1,
    route: "home_page",
    name: "Home Page",
  },
  {
    id: 2,
    route: "profile_page",
    name: "My Profile",
  },
  {
    id: 3,
    route: "favorite_page",
    name: "Favorites",
  },
  {
    id: 4,
    route: "my_offr_page",
    name: "My Offer",
  },
  {
    id: 5,
    route: "alert_page",
    name: "Alert",
  },
  {
    id: 6,
    route: "logistic_page",
    name: "Logistic",
  },
  {
    id: 7,
    route: "cart_page",
    name: "My Cart",
  },
  {
    id: 8,
    route: "notification_page",
    name: "Notification",
  },
  {
    id: 9,
    route: "buy_post_page",
    name: "Create Buy Post",
  },
  {
    id: 10,
    route: "sell_post_page",
    name: "Create Sell Post",
  },
  {
    id: 11,
    route: "contact_us_page",
    name: "Contact Us",
  },
  {
    id: 12,
    route: "privacy_policy_page",
    name: "Privacy Policy",
  },
  {
    id: 13,
    route: "tearms_conditoins_page",
    name: "Terms & Conditions",
  },
  {
    id: 14,
    route: "live_price_page",
    name: "Live price",
  },
];
const CustomNotification = () => {
  const searchRef = useRef();

  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoadTable, setPageLoadTable] = useState(false);
  const [CustomNotificationData, setCustomNotificationData] = useState([]);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dateModel, setDateModel] = useState(false);
  const [delIds, setDelIds] = useState([]);

  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [sendBtnLoader, setSendBtnLoader] = useState(false);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = styles();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];
  const [sendBy, setSendBy] = useState("post");
  const [countryRadio, setCountryRadio] = useState("all");
  const [categoryRadio, setCategoryRadio] = useState("all");
  const [subCategoryRadio, setSubCategoryRadio] = useState("all");
  const [userRadio, setUserRadio] = useState("all");
  const [redirectRadio, setRedirectRadio] = useState("all");
  const [selectPost, setSelectPost] = useState("");
  const [selectUser, setSelectUser] = useState([]);
  const [selectPage, setSelectPage] = useState({
    id: 1,
    route: "home_page",
    name: "Home Page",
  });
  const [stateRadio, setStateRadio] = useState("all");
  const [businessTypeRadio, setBusinessTypeRadio] = useState("all");

  const [usersList, setUsersList] = useState([]);
  const [postList, setPostsList] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState("");
  const [categoryList, setcategoryList] = useState("");
  const [subCategoryList, setSubCategoryList] = useState("");
  const [businessList, setBusinessList] = useState("");
  const [postId, setPostId] = useState(null);

  const [notification, setnotification] = useState("");
  const [notificationDesc, setnotificationDesc] = useState("");
  const [categoryValue, setCategoryValue] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState([]);
  const [stateValue, setStateValue] = useState([]);
  const [countryValue, setCountryValue] = useState([]);
  const [businessValue, setBusinessValue] = useState([]);

  useEffect(() => {
    clearState();
    getUserAndPostData(sendBy === "post");
    getCountryAndStateData();
    GetCommonDataApi();
    getBusinessData();
    setPageLoadTable(true);
  }, [sendBy, openCustomModal]);

  //validation
  const [notificationDescErrrorText, setnotificationDescErrrorText] =
    useState("");
  const [notificationErrorText, setnotificationErrorText] = useState("");
  const [postErrorText, setpostErrorText] = useState("");
  const [countrySelectErrorText, setCountrySelectErrorText] = useState("");
  const [categorySelectErrorText, setCategorySelectErrorText] = useState("");
  const [subCategorySelectErrorText, setSubCategorySelectErrorText] =
    useState("");
  const [selectUserErrorText, setSelectUserErrorText] = useState("");
  const [selectPageErrorText, setSelectPageErrorText] = useState("");
  const [selectPostErrorText, setSelectPostErrorText] = useState("");
  const [businessTypeErrorText, setBusinessTypeErrorText] = useState("");
  const [stateErrorText, setStateErrorText] = useState("");

  useEffect(() => {
    setHeaderParams(page, search, sortVal, sortByVal, startDateVal, endDateVal);
  }, []);

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = ""
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    setSearchParams(createSearchParams(params));
  };

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    getAllCustomNotificationData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal
    );
  }, []);

  //custom notifications data
  const getAllCustomNotificationData = (
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) => {
    const data = {
      page: page,
      type: "admin",
      download: false,
    };
    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    getApiData(BaseSetting.endpoint.getAllCustomNotifications, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setCustomNotificationData(response?.getList);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  //get user and post data
  const getUserAndPostData = (isPost) => {
    const endpoint = isPost
      ? BaseSetting.endpoint.adminPostlist
      : BaseSetting.endpoint.adminUserList;

    getApiData(endpoint, "get")
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            const extractedData = response?.map((val, i) => ({
              id: val?.id,
              name: isPost ? val?.name : val?.first_name + " " + val?.last_name,
            }));
            isPost ? setPostsList(extractedData) : setUsersList(extractedData);
          }
          setPageLoadTable(false);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoadTable(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoadTable(false);
      });
  };

  // get business data
  const getBusinessData = () => {
    getApiData(BaseSetting.endpoint.businessTypeListData, "post", {
      type: "web",
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            const extractedBusinessType = response?.map((val, i) => ({
              id: i,
              name: val?.name,
            }));

            setBusinessList(extractedBusinessType);
          }
          toast(result?.message, { type: "success" });
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // country and state data
  const getCountryAndStateData = () => {
    getApiData(BaseSetting.endpoint.countryAndStateList, "get")
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          if (!isEmpty(response)) {
            const extractedStates = response?.getStateList?.map((val, i) => ({
              id: i,
              name: val?.state,
            }));

            setStateList(extractedStates);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  // date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  const clearState = () => {
    setCategoryValue([]);
    setSubCategoryValue([]);
    setStateValue([]);
    setCountryValue([]);
    setBusinessValue([]);
    setnotification("");
    setnotificationDesc("");
    setSelectPost("");
    setSelectUser([]);
    setSelectPage({
      id: 1,
      route: "home_page",
      name: "Home Page",
    });
    setCategoryValue([]);
    setSubCategoryValue([]);
    setBusinessValue([]);
    setStateValue([]);
    allErrorFalse();
    allErrorFalseUser();

    setCountryRadio("all");
    setCategoryRadio("all");
    setSubCategoryRadio("all");
    setUserRadio("all");
    setRedirectRadio("all");
    setBusinessTypeRadio("all");
    setStateRadio("all");
    allErrorFalse();
  };

  // validation
  const validation = (type) => {
    if (type === "post") {
      if (notification.trim() === "") {
        allErrorFalse();
        setnotificationErrorText("Notification title is required");
      } else if (notificationDesc.trim() === "") {
        allErrorFalse();
        setnotificationDescErrrorText("Notification description is required");
      } else if (selectPost === "") {
        allErrorFalse();
        setpostErrorText("Please select a post");
      } else if (countryRadio === "specific" && isEmpty(countryValue)) {
        allErrorFalse();
        setCountrySelectErrorText("Please select country");
      } else if (categoryRadio === "specific" && isEmpty(categoryValue)) {
        allErrorFalse();
        setCategorySelectErrorText("Please select category");
      } else if (subCategoryRadio === "specific" && isEmpty(subCategoryValue)) {
        allErrorFalse();
        setSubCategorySelectErrorText("Please select sub-category");
      } else {
        allErrorFalse();
        sendNotification(type);
      }
    } else {
      if (notification.trim() === "") {
        allErrorFalseUser();
        setnotificationErrorText("Notification title is required");
      } else if (notificationDesc.trim() === "") {
        allErrorFalseUser();
        setnotificationDescErrrorText("Notification description is required");
      } else if (userRadio === "all" && isEmpty(selectUser)) {
        allErrorFalseUser();
        setSelectUserErrorText("please select user");
      } else if (countryRadio === "specific" && isEmpty(countryValue)) {
        allErrorFalseUser();
        setCountrySelectErrorText("Please select country");
      } else if (businessTypeRadio === "specific" && isEmpty(businessValue)) {
        allErrorFalseUser();
        setBusinessTypeErrorText("Please select business type");
      } else if (stateRadio === "specific" && isEmpty(stateValue)) {
        allErrorFalseUser();
        setStateErrorText("Please select state");
      } else if (redirectRadio === "all" && isEmpty(selectPage)) {
        allErrorFalseUser();
        setSelectPageErrorText("Please select page");
      } else if (redirectRadio === "specific" && isEmpty(selectPost)) {
        allErrorFalseUser();
        setSelectPostErrorText("Please select post");
      } else {
        allErrorFalseUser();
        sendNotification(type);
      }
    }
  };

  // send notification api
  const sendNotification = (isPost) => {
    setSendBtnLoader(true);
    const endpoint =
      isPost === "post"
        ? BaseSetting.endpoint.sendPostNotification
        : BaseSetting.endpoint.sendUserNotification;

    const data = {
      title: notification,
      description: notificationDesc,
    };

    if (!isEmpty(selectUser) && userRadio !== "all-users") {
      data.user_id = !isEmpty(selectUser) ? selectUser?.map((v) => v?.id) : [];
    }

    if (userRadio === "all-users") {
      data.user_id = "all";
    }

    if (isPost === "post") {
      if (!isEmpty(postId)) {
        data.post_id = postId;
      }
    }

    if (isPost === "user" && redirectRadio === "specific") {
      if (!isEmpty(selectPost)) {
        data.post_id = selectPost?.id;
      }
    }

    if (isPost === "user" && redirectRadio !== "specific") {
      if (!isEmpty(selectPage)) {
        data.redirect_page = selectPage?.route;
      }
    }

    if (isPost === "post") {
      data.category = !_.isEmpty(categoryValue)
        ? categoryValue?.map((v) => v?.id)
        : "all";
    }

    if (isPost === "user") {
      data.business_type = !_.isEmpty(businessValue)
        ? businessValue?.map((v) => v?.id)
        : "all";
    }

    if (isPost === "post") {
      data.sub_category = !_.isEmpty(subCategoryValue)
        ? subCategoryValue?.map((v) => v?.id)
        : "all";
    }

    data.country = !isEmpty(countryValue)
      ? countryValue?.map((v) => v?.name)
      : "all";

    if (isPost === "user") {
      data.state = !isEmpty(stateValue)
        ? stateValue?.map((v) => v?.name)
        : "all";
    }

    getApiData(endpoint, "POST", data)
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setOpenCustomModal(false);
          getAllCustomNotificationData(pageNo);
          clearState();
          setSendBy("post");
          setSendBtnLoader(false);
        } else {
          toast(result?.message, { type: "error" });
          setSendBtnLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setSendBtnLoader(false);
      });
  };

  // get common data
  const GetCommonDataApi = (list, bool = false) => {
    const endpoint = BaseSetting.endpoint.commonData;

    let data = {};

    if (!_.isEmpty(list) && _.isArray(list)) {
      let parentsIds = [];
      list.map((data) => {
        parentsIds.push(data?.id);
      });
      data.parent_ids = parentsIds;
    }

    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const tempSubCategoryList = result?.data?.subCategories;
          const categoryList = result?.data?.categories;
          const countryList = result?.data?.countries;

          if (bool === false) {
            setcategoryList(categoryList);
            setCountryList(countryList);
          } else {
            if (!_.isEmpty(tempSubCategoryList)) {
              tempSubCategoryList.map((item) => {
                subCategoryValue.map((item1) => {
                  if (item.id === item1.id) {
                    item.selected = true;
                  }
                });
              });
            }
            setSubCategoryList([...tempSubCategoryList]);
            const selectedSubCat = tempSubCategoryList.filter(
              (v) => v.selected === true
            );
            setSubCategoryValue(selectedSubCat);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // subcategory list
  const getSubCategoryList = () => {
    const endpoint = BaseSetting.endpoint.subCategoryList;

    getApiData(endpoint, "post", { type: "web" })
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          if (!_.isEmpty(data) && _.isArray(data)) {
            setSubCategoryList(data);
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const allErrorFalse = () => {
    setnotificationErrorText("");
    setnotificationDescErrrorText("");
    setpostErrorText("");
    setCountrySelectErrorText("");
    setCategorySelectErrorText("");
    setSubCategorySelectErrorText("");
  };
  const allErrorFalseUser = () => {
    setnotificationErrorText("");
    setnotificationDescErrrorText("");
    setSelectUserErrorText("");
    setCountrySelectErrorText("");
    setBusinessTypeErrorText("");
    setStateErrorText("");
    setSelectPageErrorText("");
    setSelectPostErrorText("");
  };

  const CustomNotificationColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        CustomNotificationData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },

    {
      field: "title",
      headerName: "TITLE",
      flex: 2,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "TYPE",
      minWidth: 200,
      flex: 2.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.type === "by_post"
              ? "By post"
              : params?.row?.type === "by_user"
              ? "By user"
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 200,
      flex: 2.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "state",
      headerName: "STATE",
      minWidth: 200,
      flex: 3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const simpleData = JSON.parse(params?.row?.state);
        const stringData = simpleData?.join(", ");
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {stringData}
          </Typography>
        );
      },
    },
    {
      field: "country",
      headerName: "COUNTRY",
      flex: 2,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const simpleData = JSON.parse(params?.row?.country);
        const stringData = simpleData?.join(", ");
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {stringData}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  return (
    <Grid container>
      <MiniDrawer
        header="Custom Notification"
        children={
          <div>
            <Grid
              alignItems={"center"}
              sx={{ gap: 1 }}
              container
              justifyContent={"space-between"}
              mb={"30px"}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenCustomModal(true);
                }}
              >
                Create custom notification
              </Button>
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDateModel(true);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(delIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "");
                      getAllCustomNotificationData(page);
                      setDelIds([]);
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
              </div>
            </Grid>

            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal
                    );
                    getAllCustomNotificationData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal
                    );
                  } else {
                    setHeaderParams(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal
                    );
                    getAllCustomNotificationData(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal
                    );
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={CustomNotificationData}
                columns={CustomNotificationColumns}
                disableColumnMenu
                disableDensitySelector
                hideFooter
                loading={dataLoader}
                sortingMode="server"
                apiRef={getDataGridApi}
                keepNonExistentRowsSelected
                paginationMode="server"
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableRowSelectionOnClick
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall": {
                    display: "none",
                    backgroundColor: "red",
                  },
                  "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                    display: "none",
                  },
                  "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "#674188",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                    {
                      width: "1px",
                      height: "4px",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                    {
                      background: "#F7EFE5",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "rgba(103, 65, 136, 0.5)",
                      borderRadius: "10px",
                    },
                }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value);
                      setHeaderParams(
                        1,
                        e?.target?.value,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getAllCustomNotificationData(
                          1,
                          e?.target?.value,
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      }, 800);
                    },
                  },
                }}
              />
            )}
            {!isEmpty(CustomNotificationData) &&
              isArray(CustomNotificationData) && (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    count={pagination?.totalPage}
                    page={pageNo}
                    siblingCount={2}
                    onChange={(e, value) => {
                      setPageLoad(true);
                      setPageNo(value);
                      setHeaderParams(
                        value,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal
                      );
                      getAllCustomNotificationData(value);
                    }}
                    sx={{ display: pageLoad ? "none" : "block" }}
                  />
                </div>
              )}
          </div>
        }
      />

      <CModal
        visible={openCustomModal}
        style={{ width: lg ? "50%" : sm ? "80%" : "35%" }}
        onClose={() => {
          clearState();
          setOpenCustomModal(false);
          setSendBy("post");
        }}
        title={"Create notification"}
        children={
          <>
            <Grid container padding={"30px"}>
              <Grid container gap={2}>
                <Grid item>
                  <Button
                    onClick={() => setSendBy("post")}
                    variant={sendBy === "post" ? "contained" : "outlined"}
                  >
                    By post
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setSendBy("user")}
                    variant={sendBy === "user" ? "contained" : "outlined"}
                  >
                    By user
                  </Button>
                </Grid>
              </Grid>

              {pageLoadTable ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <Grid
                  container
                  gap={3}
                  mt={"30px"}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Grid item xs={12} md={12} lg={12}>
                    <CInput
                      placeholder="Notification title"
                      value={notification}
                      onChange={(val) => {
                        setnotification(val);
                        setnotificationErrorText("");
                      }}
                      errorMsg={notificationErrorText}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <CInput
                      placeholder="Notification description"
                      value={notificationDesc}
                      onChange={(val) => {
                        setnotificationDesc(val);
                        setnotificationErrorText("");
                      }}
                      errorMsg={notificationDescErrrorText}
                    />
                  </Grid>

                  {sendBy === "post" ? (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <CAutocomplete
                          label={"Select post"}
                          selectedValue={selectPost}
                          errorMsg={postErrorText}
                          options={postList}
                          onSelect={(val) => {
                            const lastValue = val.name;
                            const foundObject = postList.find(
                              (item) => item.name === lastValue
                            );
                            setPostId([foundObject.id]);
                            setSelectPost(val);
                          }}
                        />
                      </Grid>

                      <Grid flexDirection={"column"} gap={3} container>
                        <Grid item>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Country
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={countryRadio}
                              onChange={(e) => {
                                setCountryRadio(e.target.value);
                                if (e.target.value === "all") {
                                  setCountryValue([]);
                                }
                              }}
                            >
                              <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                              <FormControlLabel
                                value="specific"
                                control={<Radio />}
                                label="Specific"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                          {countryRadio === "specific" ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <CAutocomplete
                                label="Select country"
                                multiple
                                selectedValue={countryValue}
                                options={countryList}
                                onSelect={(val) => {
                                  setCountryValue(val);
                                  allErrorFalse();
                                }}
                                errorMsg={countrySelectErrorText}
                              />
                            </Grid>
                          ) : null}
                        </Grid>

                        <Grid item>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Categories
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={categoryRadio}
                              onChange={(e) => {
                                setCategoryRadio(e.target.value);
                                if (e.target.value === "all") {
                                  getSubCategoryList();
                                  setSubCategoryValue([]);
                                }
                                allErrorFalse();
                                if (categoryRadio === "all") {
                                  setCategoryValue([]);
                                }
                              }}
                            >
                              <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                              <FormControlLabel
                                value="specific"
                                control={<Radio />}
                                label="Specific"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                          {categoryRadio === "specific" ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <CAutocomplete
                                label={"Select categories"}
                                multiple
                                selectedValue={categoryValue}
                                options={categoryList}
                                onSelect={(val) => {
                                  setCategoryValue(val);
                                  GetCommonDataApi(val, true);
                                  allErrorFalse();
                                }}
                                errorMsg={categorySelectErrorText}
                              />
                            </Grid>
                          ) : null}
                        </Grid>

                        <Grid item>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Sub categories
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={subCategoryRadio}
                              onChange={(e) => {
                                setSubCategoryRadio(e.target.value);
                                if (e.target.value === "all") {
                                  setSubCategoryValue([]);
                                }
                              }}
                            >
                              <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                              <FormControlLabel
                                value="specific"
                                control={<Radio />}
                                label="Specific"
                                disabled={
                                  selectUser.length === 0 ? false : true
                                }
                              />
                            </RadioGroup>
                          </FormControl>

                          {subCategoryRadio === "specific" ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <CAutocomplete
                                label={"Select sub categories"}
                                multiple
                                selectedValue={subCategoryValue}
                                options={subCategoryList}
                                onSelect={(val) => {
                                  setSubCategoryValue(val);
                                  allErrorFalse();
                                }}
                                errorMsg={subCategorySelectErrorText}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Select by
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={userRadio}
                          onChange={(e) => {
                            setUserRadio(e.target.value);
                            if (
                              e.target.value === "specific" ||
                              e.target.value === "all-users"
                            ) {
                              setSelectUser([]);
                            }
                          }}
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="Users"
                          />
                          <FormControlLabel
                            value="specific"
                            control={<Radio />}
                            label="Specific"
                          />
                          <FormControlLabel
                            value="all-users"
                            control={<Radio />}
                            label="All"
                          />
                        </RadioGroup>
                      </FormControl>
                      {userRadio === "all" && userRadio !== "all-users" ? (
                        <Grid item xs={12} md={12} lg={12}>
                          <CAutoComplete
                            multiple
                            label={"Select user"}
                            selectedValue={selectUser}
                            errorMsg={selectUserErrorText}
                            options={usersList}
                            onSelect={(val) => {
                              setSelectUser(val);
                            }}
                          />
                        </Grid>
                      ) : null}
                      {userRadio !== "specific" ? null : (
                        <Grid flexDirection={"column"} gap={3} container>
                          <Grid item>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Country
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={countryRadio}
                                onChange={(e) => {
                                  setCountryRadio(e.target.value);
                                  if (e.target.value === "all") {
                                    setCountryValue([]);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="all"
                                  control={<Radio />}
                                  label="All"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                                <FormControlLabel
                                  value="specific"
                                  control={<Radio />}
                                  label="Specific"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                            {countryRadio === "specific" ? (
                              <Grid item xs={12} md={12} lg={12}>
                                <CAutocomplete
                                  label="Select country"
                                  multiple
                                  selectedValue={countryValue}
                                  options={countryList}
                                  onSelect={(val) => {
                                    setCountryValue(val);
                                  }}
                                  errorMsg={countrySelectErrorText}
                                />
                              </Grid>
                            ) : null}
                          </Grid>

                          <Grid item>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Business type
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={businessTypeRadio}
                                onChange={(e) => {
                                  setBusinessTypeRadio(e.target.value);
                                  if (businessTypeRadio === "all") {
                                    setBusinessValue([]);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="all"
                                  control={<Radio />}
                                  label="All"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                                <FormControlLabel
                                  value="specific"
                                  control={<Radio />}
                                  label="Specific"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                            {businessTypeRadio === "specific" ? (
                              <Grid item xs={12} md={12} lg={12}>
                                <CAutocomplete
                                  label={"Business type"}
                                  multiple
                                  selectedValue={businessValue}
                                  options={businessList}
                                  onSelect={(val) => {
                                    setBusinessValue(val);
                                  }}
                                  errorMsg={businessTypeErrorText}
                                />
                              </Grid>
                            ) : null}
                          </Grid>

                          <Grid item>
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                {"State"}
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={stateRadio}
                                onChange={(e) => {
                                  setStateRadio(e.target.value);
                                  if (e.target.value === "all") {
                                    setBusinessValue([]);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value="all"
                                  control={<Radio />}
                                  label="All"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                                <FormControlLabel
                                  value="specific"
                                  control={<Radio />}
                                  label="Specific"
                                  disabled={
                                    selectUser.length === 0 ? false : true
                                  }
                                />
                              </RadioGroup>
                            </FormControl>

                            {stateRadio === "specific" ? (
                              <Grid item xs={12} md={12} lg={12}>
                                <CAutocomplete
                                  label={"Select state"}
                                  multiple
                                  selectedValue={stateValue}
                                  options={stateList}
                                  onSelect={(val) => {
                                    setStateValue(val);
                                  }}
                                  errorMsg={stateErrorText}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      )}
                      <Grid gap={3} container>
                        <Grid item>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Select redirect page
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={redirectRadio}
                              onChange={(e) => {
                                setRedirectRadio(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="Page"
                              />
                              <FormControlLabel
                                value="specific"
                                control={<Radio />}
                                label="Post"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {redirectRadio === "all" ? (
                          <Grid item xs={12} md={12} lg={12}>
                            <CAutocomplete
                              label=" Select page (redirect to)"
                              selectedValue={selectPage}
                              options={RedirectPageData}
                              onSelect={(val) => {
                                setSelectPage(val);
                              }}
                              errorMsg={selectPageErrorText}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={12} lg={12}>
                            <CAutocomplete
                              label=" Select post (redirect to)"
                              selectedValue={selectPost}
                              options={postList}
                              // disabled={selectPage.length !== 0}
                              onSelect={(val) => {
                                setSelectPost(val);
                              }}
                              errorMsg={selectPostErrorText}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    justifyContent={"space-evenly"}
                    gap={2}
                    wrap="nowrap"
                  >
                    <Grid item xs={6}>
                      <CButton
                        onClick={() => validation(sendBy)}
                        fullWidth
                        variant="contained"
                        loading={sendBtnLoader}
                      >
                        Send
                      </CButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CButton
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          setOpenCustomModal(false);
                          clearState();
                          setSendBy("post");
                        }}
                      >
                        Cancel
                      </CButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  getAllCustomNotificationData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default CustomNotification;
