import { Grid } from "@mui/material";
import React from "react";
import MiniDrawer from "../../Components/Drawer";

export default function Buyer() {
  return (
    <Grid container>
      <MiniDrawer children={<div>Buyer</div>} />
    </Grid>
  );
}
