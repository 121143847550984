import { Suspense, useEffect } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./App/Pages/Login";
import Category from "./App/Pages/Category";
import Seller from "./App/Pages/Seller";
import Buyer from "./App/Pages/Buyer";
import Conditions from "./App/Pages/Conditions";
import BusinessType from "./App/Pages/BusinessType";
import Plan from "./App/Pages/Plan";
import ActiveUsers from "./App/Pages/ActiveUsers";
import AllUser from "./App/Pages/AllUsers";
import IceBreakingQuestions from "./App/Pages/IceBreakingQuestions";
import ReportUsers from "./App/Pages/ReportUsers";
import SystemParameters from "./App/Pages/SystemParameters";
import PostDetails from "./App/Pages/AllPost/PostDetails/index";
import ExpiredPosts from "./App/Pages/ExpiredPosts";
import { useDispatch, useSelector } from "react-redux";
import ExpiredPostDetails from "./App/Pages/ExpiredPosts/ExpirePostDetails";
import AllPosts from "./App/Pages/AllPost";
import VerifiedUsers from "./App/Pages/VerifyUsers";
import UserDetails from "./App/Pages/VerifyUsers/UserDetails";
import Countries from "./App/Pages/Countries";
import InterestedPosts from "./App/Pages/InterestedPosts";
import Logistics from "./App/Pages/Logistics";
import ContactUS from "./App/Pages/ContactUs";
import CMS from "./App/Pages/CMS";
import CreateUpdateCMS from "./App/Pages/CMS/CreateUpdateCMS";
import EmailTemplate from "./App/Pages/EmailTemplate";
import SmsTemplate from "./App/Pages/SMSTemplate";
import _, { isEmpty } from "lodash";
import BuyPost from "./App/Pages/AllPost/EditPost/BuyPost";
import SellPost from "./App/Pages/AllPost/EditPost/SellPost";
import { store } from "./App/Redux/Store/configureStore";
import NotVerifiedUsers from "./App/Pages/NotVerifiedUsers";
import MyProfile from "./App/Pages/MyProfile";
import NotificationTemplate from "./App/Pages/NotificationTemplate/index";
import RatingList from "./App/Pages/RatingList/index";
import AlertData from "./App/Pages/Alert Data";
import BulkMail from "./App/Pages/BulkMail";
import BulkTemplate from "./App/Pages/BulkTemplate";
import Chats from "./App/Pages/ChatPage";
import socket from "./App/Apis/socket";
import SendNotifications from "./App/Pages/SendNotifications";
import Groups from "./App/Pages/Groups";
import NewsLetterList from "./App/Pages/NewsLetter";
import SendEmails from "./App/Pages/NewsLetter/SendEmails";
import SendNewsLetterList from "./App/Pages/NewsLetter/sendNewsLetterList";
import Translate from "./App/Pages/Translate";
import EditTranslation from "./App/Pages/Translate/EditTranslation";
import authActions from "./App/Redux/Reducers/Auth/actions";
import CustomNotification from "./App/Pages/CustomNotification";
import ChangePassword from "./App/Pages/ChangePassword";
import DashBoard from "./App/Pages/Dashboard";
import FeedBack from "./App/Pages/FeedBack";
import NotAddedProfileUsers from "./App/Pages/NotAddedProfileUers";
import States from "./App/Pages/States";
import Cities from "./App/Pages/Cities";
import SEODetails from "./App/Pages/SEODetails";

function App() {
  const { accessToken } = useSelector((state) => state.auth);
  const { setNotificationData } = authActions;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const authState = store?.getState() || {};
    const token = authState?.auth?.accessToken || "";
    const webUrl = window.location.href;
    const webUrlArray = webUrl.split("/");
    const length = !_.isEmpty(webUrlArray) ? webUrlArray.length - 1 : null;
    const pageName = webUrlArray[length];

    if (!_.isEmpty(token) && pageName == "") {
      window.location.replace("/dashboard");
    } else if (_.isEmpty(token) && pageName != "") {
      window.location.replace("/");
    }

    // if (token) {
    //   const decodedToken = jwt_decode(token);
    //   const tokenExpirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    //   const currentTime = Date.now();
    //   const timeUntilExpiration = tokenExpirationTime - currentTime;

    //   setTimeout(() => {
    //     localStorage.clear();
    //     navigate("/login"); // redirect to login page
    //     toast("Session timeout", { type: "info" });
    //   }, timeUntilExpiration);
    // }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(accessToken)) {
      socket.connect();
      const data = {
        user_id: 1,
        type: "unread",
      };
      socket.emit("userConnect", data, (callBackData) => {
        if (!isEmpty(callBackData?.data?.Data)) {
          dispatch(setNotificationData(true));
        }
      });
    }
  }, [accessToken]);

  return (
    <Suspense>
      <Routes>
        {_.isEmpty(accessToken) ? (
          <>
            <Route exact path={"/"} element={<Login />} />
            <Route path={"/login"} element={<Login />} />
          </>
        ) : (
          <>
            <Route path={"/category"} element={<Category />} />
            <Route path={"/seller"} element={<Seller />} />
            <Route path={"/buyer"} element={<Buyer />} />
            <Route path={"/all-posts"} element={<AllPosts />} />
            <Route path={"/post-details"} element={<PostDetails />} />
            <Route path={"/conditions"} element={<Conditions />} />
            <Route path={"/plan"} element={<Plan />} />
            <Route path={"/business-type"} element={<BusinessType />} />
            <Route path={"/users"} element={<ActiveUsers />} />
            <Route path={"/all-users"} element={<AllUser />} />
            <Route
              path={"/ice-breaking-questions"}
              element={<IceBreakingQuestions />}
            />
            <Route path={"/report-users"} element={<ReportUsers />} />
            <Route path={"/system-parameters"} element={<SystemParameters />} />
            <Route path={"/expired-posts"} element={<ExpiredPosts />} />
            <Route
              path={"/expired-posts-details"}
              element={<ExpiredPostDetails />}
            />
            <Route path={"/verify-users"} element={<VerifiedUsers />} />
            <Route path={"/user-details"} element={<UserDetails />} />
            <Route path={"/countries"} element={<Countries />} />
            <Route path={"/interested-posts"} element={<InterestedPosts />} />
            <Route path={"/logistics"} element={<Logistics />} />
            <Route path={"/contact-us"} element={<ContactUS />} />
            <Route path={"/cms"} element={<CMS />} />
            <Route path={"/create-update-cms"} element={<CreateUpdateCMS />} />
            <Route path={"/email-template"} element={<EmailTemplate />} />
            <Route path={"/sms-template"} element={<SmsTemplate />} />
            <Route
              path={"/notification-template"}
              element={<NotificationTemplate />}
            />
            <Route path={"/buy-post"} element={<BuyPost />} />
            <Route path={"/sell-post"} element={<SellPost />} />
            <Route path={"/my-profile"} element={<MyProfile />} />
            <Route path={"/rating-list"} element={<RatingList />} />
            <Route path={"/alert-data"} element={<AlertData />} />
            <Route path={"/bulk-mail"} element={<BulkMail />} />
            <Route path={"/bulk-template"} element={<BulkTemplate />} />
            <Route path={"/chat-page"} element={<Chats />} />
            <Route
              path={"/send-notifications"}
              element={<SendNotifications />}
            />
            <Route
              path={"/not-verified-users"}
              element={<NotVerifiedUsers />}
            />
            <Route path={"/groups"} element={<Groups />} />
            <Route path={"/news-letter-list"} element={<NewsLetterList />} />
            <Route path={"/send-emails"} element={<SendEmails />} />
            <Route
              path={"/send-news-letter-list"}
              element={<SendNewsLetterList />}
            />
            <Route path={"/translate-page"} element={<Translate />} />
            <Route
              path={"/edit-translation/:language_id"}
              element={<EditTranslation />}
            />
            <Route
              path={"/custom-notification"}
              element={<CustomNotification />}
            />
            <Route path={"/dashboard"} element={<DashBoard />} />
            <Route path={"/change-password"} element={<ChangePassword />} />
            <Route path={"/feed-back"} element={<FeedBack />} />
            <Route
              path={"/not-added-profile-users"}
              element={<NotAddedProfileUsers />}
            />
            <Route path={"/states"} element={<States />} />
            <Route path={"/cities"} element={<Cities />} />
            <Route path={"/seo-details"} element={<SEODetails />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

export default App;
