import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CSelect from "../../Components/CSelect";
import theme, { FontFamily } from "../../Config/theme";
import CModal from "../../Components/CModal";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import _, { isArray, isEmpty, isNull } from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

//Date Filter
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/";
import styles from "./styles";
import NoData from "../../Components/NoData";

const InterestedPost = () => {
  const classes = styles();
  const searchRef = useRef();

  // interested post data
  const [interestedPostData, setInterestedPostData] = useState([]);

  //loaders and pagination
  const [pageLoad, setPageLoad] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);

  //modal
  const [openViewMoreModal, setOpenViewMoreModal] = useState(false);
  const [modalPage, setModalPage] = useState(1);
  const [modalLoad, setModalLoad] = useState(false);
  const [interestedUserData, setInterestedUserData] = useState([]);
  const [modalPagination, setModalPagination] = useState();

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  // download data
  const [selectFileType, setSelectFileType] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // date filter
  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowChange, setRowChange] = useState(false);

  //get params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [postIds, setPostIds] = useState(downloadIdArr);

  //responsive
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (openViewMoreModal === true) {
      getInterestedUsersList();
    }
  }, [modalPage]);

  useEffect(() => {
    setPageLoad(true);
    getInterestedPostData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal
    );
  }, []);

  //set params in URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Post id",
    "Post name",
    "Category",
    "Sub category",
    "Condition",
    "Country",
    "Type",
    "Pricing terms",
    "Unit price",
    "Interested users count",
  ]);

  //get interested post data api
  const getInterestedPostData = (
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) => {
    setDataLoader(true);
    const data = {
      page: page,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    const endpoint = `${BaseSetting.endpoint.interestedPostList}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setInterestedPostData(response?.array);
          setPagination(response?.pagination);
          setTimeout(() => {
            setPageLoad(false);
            setDataLoader(false);
          }, 100);
        } else {
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  };

  //interested users list
  const getInterestedUsersList = (data) => {
    setModalLoad(true);
    getApiData(BaseSetting.endpoint.interestedUserList, "post", {
      page: modalPage,
      post_id: `${data?.postID}`,
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.UserData;

          if (!isEmpty(response) && isArray(response)) {
            setInterestedUserData(response);
            setModalPagination(result?.data?.pagination);
          }
          setModalLoad(false);
        } else {
          // toast(result?.message, { type: "error" });
          setModalLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setModalLoad(false);
      });
  };

  //download data in different formats
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      format: "a4",
      orientation: "landscape",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save(`InterestedPost-Data.pdf`);
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `InterestedPost-Data.csv`;
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `InterestedPost-Data.xlsx`);
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: postIds,
    };

    if (!_.isEmpty(startDateVal)) {
      data.start_date = startDateVal;
    }

    if (!_.isEmpty(endDateVal)) {
      data.end_date = endDateVal;
    }
    getApiData(BaseSetting.endpoint.interestedPostList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.array;
          let dataSort = [];
          response.map((val, index) => {
            let newObj = {
              No: index + 1,
              "Post id": val?.post_id,
              "Post name": val?.name,
              Category: val?.category_name,
              "Sub category": val?.subcategory_name,
              Condition: val?.conditions,
              Country: val?.country,
              Type: val?.type,
              "Pricing terms": val?.pricing_term,
              "Unit price": val?.unitPrice,
              "Interested users count": val?.user_count,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const interestedPostColumns = [
    {
      field: "id",
      headerName: "No.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        interestedPostData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 230,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              // onClick={() => openModal(params?.row?.id)}
              onClick={() => {
                setOpenViewMoreModal(true);
                getInterestedUsersList(params?.row);
              }}
            >
              View more
            </Button>
          </>
        );
      },
    },
    {
      field: "post_id",
      headerName: "POST ID",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "POST NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category_name",
      headerName: "CATEGORY",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subcategory_name",
      headerName: "SUB CATEGORY",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "conditions",
      headerName: "CONDITION",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pricing_term",
      headerName: "PRICING TERMS",
      width: 180,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "unitPrice",
      headerName: "UNIT PRICE",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user_count",
      headerName: "INTERESTED USERS COUNT",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
  ];

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  //Date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Interested Posts"
        children={
          <>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={3}></Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  flexWrap: "wrap",
                }}
              >
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(postIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPostIds([]);
                      setStartDate("");
                      setEndDate("");
                      setSearchVal("");
                      setHeaderParams(page, "", "", "", "", "", []);
                      getInterestedPostData(page, "", "", "", "", "");
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}

                <Button variant="contained" onClick={() => setDateModel(true)}>
                  Filter date
                </Button>
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>

            <Grid container mt={"30px"}>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDate,
                        endDate,
                        downloadId
                      );
                      getInterestedPostData(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDate,
                        endDate
                      );
                    } else {
                      setHeaderParams(page, search, "", "", "", "", []);
                      getInterestedPostData(page, search, "", "", "", "");
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={interestedPostData}
                  columns={interestedPostColumns}
                  getRowId={(row) => row.id}
                  disableColumnMenu
                  disableDensitySelector
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  loading={dataLoader}
                  hideFooter={isEmpty(postIds)}
                  hideFooterPagination
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  slots={{ toolbar: GridToolbar }}
                  disableRowSelectionOnClick
                  rowSelectionModel={postIds}
                  checkboxSelection
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setPostIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDate,
                        endDate,
                        downloadIdString
                      );
                    }
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [search],
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDate,
                          endDate,
                          downloadId
                        );
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          getInterestedPostData(
                            1,
                            e?.target?.value?.toLowerCase(),
                            sortVal,
                            sortByVal,
                            startDate,
                            endDate
                          );
                        }, 800);
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(interestedPostData) && isArray(interestedPostData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDate,
                      endDate,
                      downloadId
                    );
                    getInterestedPostData(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDate,
                      endDate
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />

      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  getInterestedPostData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
      <CModal
        visible={openViewMoreModal}
        onClose={() => setOpenViewMoreModal(false)}
        title={"Interested users"}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        children={
          modalLoad ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "50vh",
              }}
            >
              <CircularProgress
                style={{ color: BaseColor.primary }}
                size={30}
              />
            </div>
          ) : (
            <div style={{ padding: "20px" }}>
              {!isEmpty(interestedUserData) && isArray(interestedPostData) ? (
                interestedUserData?.map((item) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      style={{
                        background: BaseColor.offWhite,
                        padding: 10,
                        borderRadius: 10,
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 5,
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={6}>
                        <span
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: 16,
                          }}
                        >
                          {item?.first_name} {item?.last_name}
                        </span>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  style={{
                    // height: h",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoData />
                </Grid>
              )}

              {!isEmpty(interestedUserData) && isArray(interestedUserData) ? (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Pagination
                    count={modalPagination?.totalPage}
                    defaultPage={1}
                    onChange={(e, value) => setModalPage(value)}
                    sx={{ display: modalLoad ? "none" : "block" }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )
        }
      />
    </Grid>
  );
};

export default InterestedPost;
