import { makeStyles } from "@mui/styles";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/theme"

const styles = makeStyles((theme) => ({
  maindiv: {
    backgroundColor: BaseColor.whiteColor,
  },
  header: {
    fontFamily: FontFamily.SemiBold,
    fontSize: "30px",
    color: BaseColor.primary,
    "@media (max-width:1500px)": {
      fontSize: 26,
    },
    "@media (max-width:900px)": {
      fontSize: 22,
    },
    "@media (max-width:400px)": {
      fontSize: 20,
    },
  },
  title: {
    fontFamily: FontFamily.SemiBold,
    fontSize: "20px",
  },
  roundDiv: {
    borderRadius: "50%",
    border: `2px solid ${BaseColor.primary}`,
    width: "80px",
    height: "80px",
  },

  metaData: {
    color: BaseColor.textGray,
    fontFamily: FontFamily.Medium,
    fontSize: "15px",
  },
  tag: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "100%",
    fontFamily: FontFamily.SemiBold,
    fontSize: "15px",
    backgroundColor: BaseColor.offWhite,
    borderRadius: "5px",
    cursor: "pointer",
  },
  icondiv: {
    background: BaseColor.primary,
    height: "30px",
    width: "30px",
    marginLeft: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },

  imgUploadHeader: {
    color: BaseColor.textColor,
    fontFamily: FontFamily.Regular,
    fontWeight: 800,
    fontSize: 14,
    textAlign: "center",
  },
  pointer: { cursor: "pointer !important" },

  imageContainer:{
    border: `1px dashed ${BaseColor.primary}`,
    height: 200,
    width: 250,
    background: BaseColor.whiteColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 5,
  }
}));

export default styles;
