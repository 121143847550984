import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import CModal from "../../Components/CModal";
import theme, { FontFamily } from "../../Config/theme";
import _, { isArray, isEmpty, isNull } from "lodash";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import CButton from "../../Components/CButton";

import { createSearchParams, useSearchParams } from "react-router-dom";
import CSelect from "../../Components/CSelect";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";

const NewsLetter = () => {
  const [pageLoad, setPageLoad] = useState(false);
  const [newsletterData, setNewsLetterData] = useState([]);

  // const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [dataLoader, setDataLoader] = useState(false);

  const [searchVal, setSearchVal] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");

  const [btnDisable, setBtnDisable] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);

  const [groupIdErrorText, setGroupIdErrorText] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [groupId, setGroupId] = useState("");

  const [rowChange, setRowChange] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Group id",
    "Email",
    "Created at",
  ]);

  const [selectFileType, setSelectFileType] = useState("");

  const [dateModel, setDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";

  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [userIds, setUserIds] = useState(downloadIdArr);

  const [groupList, setGroupList] = useState([]);

  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
    allGroupApiCall();
  }, []);

  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }

    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    setPageLoad(true);
    getAllNewsLetter(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, []);

  const validation = () => {
    if (_.isEmpty(groupId)) {
      setGroupIdErrorText("please select the group");
    } else {
      addToGroupApiCall();
    }
  };

  //all users list
  async function getAllNewsLetter(
    page,
    search,
    sort,
    sortBy,
    start_date,
    end_date
  ) {
    const data = {
      page: page,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      data.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      data.end_date = end_date;
    }

    getApiData(BaseSetting.endpoint.newsLetterList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setNewsLetterData(response?.email);
          setPagination(response?.pagination);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          // toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  //Download data
  function getDownloadData(type) {
    let data = {
      // attributes: visibleColumns,
      download: true,
      download_id: userIds,
    };
    getApiData(BaseSetting.endpoint.newsLetterList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.email;
          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              "Group id": val.group_id,
              Email: val.email,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
          toast(result?.message, { type: "success" });
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("News-letter.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "News-letter.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "News-letter.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  async function allGroupApiCall() {
    try {
      const data = {
        type: "web",
      };

      const response = await getApiData(
        BaseSetting.endpoint.groupList,
        "post",
        data
      );
      if (response?.status) {
        if (!_.isEmpty(response?.data)) {
          setGroupList(response?.data?.group);
        }
      } else {
        // toast(response?.message, { type: "error" });
      }
    } catch (error) {
      toast(error.toString(), { type: "error" });
    }
  }
  async function addToGroupApiCall() {
    setBtnLoad(true);
    setBtnDisable(true);
    try {
      const data = {
        id: _.isArray(id) ? id : [id],
        group_id: groupId?.id,
      };

      const response = await getApiData(
        BaseSetting.endpoint.addToGroup,
        "post",
        data
      );
      if (response?.status) {
        toast(response?.message, { type: "success" });
        setGroupId("");
        setId("");
        setOpenModal(false);
        getAllNewsLetter(page);
        setBtnDisable(false);
        setBtnLoad(false);
        setId([]);
        setUserIds([]);
        setHeaderParams(
          page,
          search,
          sortVal,
          sortByVal,
          startDateVal,
          endDateVal,
          []
        );
      } else {
        toast(response?.message, { type: "error" });
        setBtnDisable(false);
        setBtnLoad(false);
      }
    } catch (error) {
      toast(error.toString(), { type: "error" });
      setBtnDisable(false);
      setBtnLoad(false);
    }
  }

  const newsletterColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        newsletterData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "group_id",
      headerName: "GROUP ID",
      flex: 0.3,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 300,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Grid container alignItems={"center"} justifyContent={"center"}>
            {isEmpty(userIds) && (
              <Tooltip title="Add to group" placement="top" arrow>
                <CButton
                  onClick={() => {
                    setId(params?.row?.id);
                    setOpenModal(true);
                  }}
                >
                  Add to group
                </CButton>
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
  ];

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          // overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="News Letter Subscribe"
        children={
          <>
            <Grid container>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                {!isEmpty(userIds) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenModal(true);
                      setId(userIds);
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Add to group
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => setDateModel(true)}
                  style={{ marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(userIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(page, "", "", "", "", "", []);
                      getAllNewsLetter(page);
                      setUserIds([]);
                      setSearchVal("");
                      setDataLoader(true);
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Clear Filters
                  </Button>
                ) : null}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                  style={{ marginBottom: "25px" }}
                />
              </Grid>
              {pageLoad ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={30}
                  />
                </div>
              ) : (
                <DataGrid
                  sortModel={
                    sortByVal === "asc" || sortByVal === "desc"
                      ? [
                          {
                            field: sortVal,
                            sort: sortByVal,
                          },
                        ]
                      : []
                  }
                  onSortModelChange={(model) => {
                    setDataLoader(true);
                    if (!_.isEmpty(model)) {
                      setHeaderParams(
                        isPage,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllNewsLetter(
                        page,
                        search,
                        model[0]?.field,
                        model[0].sort,
                        startDateVal,
                        endDateVal
                      );
                    } else {
                      setHeaderParams(
                        isPage,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getAllNewsLetter(
                        page,
                        search,
                        "",
                        "",
                        startDateVal,
                        endDateVal
                      );
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  rows={newsletterData}
                  columns={newsletterColumns}
                  disableColumnMenu
                  hideFooter={isEmpty(userIds)}
                  hideFooterPagination
                  loading={dataLoader}
                  disableDensitySelector
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  sortingMode="server"
                  apiRef={getDataGridApi}
                  disableSelectionOnClick
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  style={{ width: "100%" }}
                  slots={{ toolbar: GridToolbar }}
                  disableRowSelectionOnClick
                  checkboxSelection
                  rowSelectionModel={userIds}
                  keepNonExistentRowsSelected
                  paginationMode="server"
                  onRowSelectionModelChange={(id) => {
                    setUserIds(id);
                    const downloadIdString = id.join(",");
                    if (rowChange === true) {
                      setHeaderParams(
                        page,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadIdString
                      );
                    }
                    getAllNewsLetter(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString
                    );
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [searchVal],
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall":
                      {
                        display: "none",
                        backgroundColor: "red",
                      },
                    "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                      display: "none",
                    },
                    "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "#674188",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                      {
                        width: "1px",
                        height: "4px",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                      {
                        background: "#F7EFE5",
                      },
                    ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                      {
                        background: "rgba(103, 65, 136, 0.5)",
                        borderRadius: "10px",
                      },
                  }}
                  slotProps={{
                    columnsPanel: {
                      onChange: (e) => {
                        if (e.target.checked == true) {
                          visibleColumns.push(e.target.name);
                        } else {
                          const index = visibleColumns.indexOf(e.target.name);
                          if (index > -1) {
                            visibleColumns.splice(index, 1);
                          }
                        }
                      },
                    },
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      onChange: (e) => {
                        setDataLoader(true);
                        setSearchVal(e.target.value?.toLowerCase());
                        setHeaderParams(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal,
                          downloadId
                        );
                        getAllNewsLetter(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      },
                    },
                  }}
                />
              )}
            </Grid>
            {!isEmpty(newsletterData) && isArray(newsletterData) && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getAllNewsLetter(
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={openModal}
        style={{ width: "25%" }}
        onClose={() => {
          setOpenModal(false);
          setId("");
          setGroupId("");
          setGroupIdErrorText("");
        }}
        title={"Add to group"}
        children={
          <div style={{ padding: "20px" }}>
            <CSelect
              placeholder={"Select group"}
              data={groupList}
              fullObj
              value={groupId}
              onSelect={(val) => {
                setGroupIdErrorText("");
                setGroupId(val);
              }}
              errorMsg={groupIdErrorText}
            />
            <CButton
              btnStyle={{ marginTop: "10px" }}
              onClick={() => {
                validation();
              }}
              fullWidth
              variant="contained"
              disabled={btnDisable}
              loading={btnLoad}
            >
              Add to group
            </CButton>
          </div>
        }
      />
      <CModal
        visible={dateModel}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModel(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  getAllNewsLetter(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModel(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default NewsLetter;
