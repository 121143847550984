/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import { useMediaQuery } from "@mui/material";

// const sm = useMediaQuery(theme.breakpoints.down("sm"));

const styles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: BaseColor.whiteColor,
    paddingBottom: 40
  },
  messageText: {
    fontSize: 18,
    color: BaseColor.textColor,
    fontFamily: FontFamily.SemiBold,
    "@media (max-width:600px)": {
      fontSize: 16,
    },
  },
  garbageMainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  garbageImg: {
    height: 60,
    width: 63,
    borderRadius: 30,
    objectFit: "contain",
    backgroundColor: BaseColor.disablePrimary,
    [theme.breakpoints.down("lg")]: {
      height: 50,
      width: 50,
    },
  },
  title: {
    fontSize: 20,
    fontFamily: FontFamily.Bold,
    color: BaseColor.textColor,
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    lineHeight: 1,
    padding: `0px !important`,
    margin: "0px !important",
    WebkitLineClamp: 1,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  imageContainer: {
    height: 60,
    width: 60,
    borderRadius: 60,
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      height: 50,
      width: 50,
    },
  },
  imagePlaceholder: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icons:{
marginLeft: 5
  },
  imagePlaceholderText: {
    fontSize: 26,
    fontFamily: FontFamily.Bold,
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  name: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    color: BaseColor.textColor,
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  userType: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: BaseColor.textColor,
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    "@media (max-width:600px)": {
      fontSize: 12,
    },
  },
  locationText: {
    fontSize:  12,
    fontFamily: FontFamily.Regular,
    // color: BaseColor.primary,
    // textOverflow: "ellipsis",

    // overflowWrap: "break-word",
  },
  locationContainer: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "row",
    marginTop: 4,
    marginLeft: -2
  },
  chatImg: {
    height: 35,
    width: 35,
    borderRadius: 5,
  },
  flagImg: {
    height: 20,
    width: 30,
    borderRadius: 5,
  },

  chatListContainer: {
    marginTop: 20,
    minHeight: "60vh",
    maxHeight: "59.5vh",
    overflowY: "scroll",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "60vh",
    },
  },

  chatContainer: {
    "@media (max-width: 900px)": {
      display: "none",
    },
  },

  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transperent",
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: BaseColor.disablePrimary,
      borderRadius: 10,
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      boxShadow: "inset 0 0 5px grey",
    },
  },
  CPageHeader: {
    "@media (max-width: 1200px)": {
      marginTop: "-10px !important",
      marginBottom: "-10px !important",
    },
  },
});
export default styles;
