import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { isArray, isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import BaseColor from "../../Config/Color";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CButton from "../../Components/CButton";
import theme, { FontFamily } from "../../Config/theme";
import CSelect from "../../Components/CSelect";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import _ from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";
import CModal from "../../Components/CModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import styles from "./styles";

const NotVerifiedUsers = () => {
  const searchRef = useRef();
  const classes = styles();

  // responsive variables
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // not verified users data
  const [notVerifiedUsersData, setNotVerifiedUsersData] = useState([]);

  //pagination and loader
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);

  //download data
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "Name",
    "Email",
    "Phone Code",
    "Phone",
    "Send notification count",
    "Status",
    "Created at",
  ]);

  //search and sort
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowChange, setRowChange] = useState(false);

  //set params from URL
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";

  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";

  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];

  const [selIds, setselIds] = useState(downloadIdArr);

  // filter date
  const [dateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //notification count
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    setHeaderParams(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set params to URL
  const setHeaderParams = (
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }
    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  useEffect(() => {
    setPageLoad(true);
    getNotVerifiedUsersData(
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, []);

  // not verified users list api call
  function getNotVerifiedUsersData(
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) {
    const data = {
      page: page,
      type: "admin",
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }

    getApiData(BaseSetting.endpoint.notVerifiedUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setNotVerifiedUsersData(response?.userList);
          setPagination(response?.pagination);
          setNotificationCount(response?.total_count);
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  }

  //send notification api call
  const sendNotification = (id) => {
    getApiData(BaseSetting.endpoint.notVerifiedUsersNotification, "post", {
      userId: id,
    })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          setPageLoad(false);
          setselIds([]);
          setHeaderParams(
            page,
            search,
            sortVal,
            sortByVal,
            startDateVal,
            endDateVal,
            []
          );
          getNotVerifiedUsersData(
            page,
            search,
            sortVal,
            sortByVal,
            startDateVal,
            endDateVal,
            []
          );
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  };

  // download data in different format
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save("NotVerified-Data.pdf");
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "NotVerified-Data.csv";
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "NotVerified-Data.xlsx");
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data
  function getDownloadData(type) {
    let data = {
      download: true,
      type: "admin",
      page: 1,
      download_id: selIds,
    };
    getApiData(BaseSetting.endpoint.notVerifiedUsersList, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.userList;
          let dataSort = [];

          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              Name: val.first_name + " " + val.last_name,
              Email: val.email,
              "Phone code": val?.phone_code_id,
              Phone: val.phone,
              "Send notification count": val?.count,
              Status:
                val.status === "1"
                  ? "Active"
                  : val.status === "2"
                  ? "Verified"
                  : val.status === "3"
                  ? "Pending"
                  : val.status === "-1"
                  ? "Remove"
                  : "",
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });

          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const usersColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      minWidth: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        notVerifiedUsersData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          isEmpty(selIds) && (
            <CButton onClick={() => sendNotification([params?.row?.id])}>
              Send notification
            </CButton>
          )
        );
      },
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      minWidth: 150,
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      minWidth: 150,
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 150,
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: "PHONE",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone_code_id",
      headerName: "PHONE CODE",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "count",
      headerName: "SEND NOTIFICATION COUNT",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span>
          {params?.row?.status === "1"
            ? "Active"
            : params?.row?.status === "2"
            ? "Verified"
            : params?.row?.status === "3"
            ? "Pending"
            : params?.row?.status === "-1"
            ? "Remove"
            : ""}
        </span>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
  ];

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  //date filter
  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Not Verified Users"
        children={
          <div>
            <Grid container justifyContent={"space-between"} mb={"30px"}>
              <div
                style={{
                  border: `2px solid ${BaseColor.primary}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px 15px",
                  borderRadius: "10px",
                }}
              >
                Sent notification: {notificationCount}
              </div>
              <div style={{ display: "flex" }}>
                {!isEmpty(selIds) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendNotification(selIds);
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Send notification
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={() => setDateModal(true)}
                  style={{ marginRight: "20px" }}
                >
                  Filter date
                </Button>
                {!_.isEmpty(search) ||
                !_.isEmpty(sortVal) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(selIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                      setHeaderParams(page, "", "", "", "", "", []);
                      getNotVerifiedUsersData(page);
                      setselIds([]);
                      setSearchVal("");
                      setDataLoader(true);
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                <CSelect
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getNotVerifiedUsersData(
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal
                    );
                  } else {
                    setHeaderParams(
                      isPage,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getNotVerifiedUsersData(
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal
                    );
                  }
                }}
                disableColumnFilter
                disableColumnSelector
                rows={notVerifiedUsersData}
                columns={usersColumns}
                disableColumnMenu
                disableDensitySelector
                sortingMode="server"
                apiRef={getDataGridApi}
                hideFooter={isEmpty(selIds)}
                hideFooterPagination
                loading={dataLoader}
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={selIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  const downloadIdString = id.join(",");
                  setselIds(id);
                  if (rowChange === true) {
                    setHeaderParams(
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString
                    );
                  }

                  getNotVerifiedUsersData(
                    page,
                    search,
                    sortVal,
                    sortByVal,
                    startDateVal,
                    endDateVal,
                    downloadIdString
                  );
                }}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                sx={{
                  "& .MuiInputBase-root button svg.MuiSvgIcon-fontSizeSmall": {
                    display: "none",
                    backgroundColor: "red",
                  },
                  "& .css-dwjt6a-MuiButtonBase-root-MuiIconButton-root": {
                    display: "none",
                  },
                  "&:hover .css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "#674188",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar":
                    {
                      width: "1px",
                      height: "4px",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                    {
                      background: "#F7EFE5",
                    },
                  ".css-axafay-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                    {
                      background: "rgba(103, 65, 136, 0.5)",
                      borderRadius: "10px",
                    },
                }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getNotVerifiedUsersData(
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      }, 800);
                    },
                  },
                }}
              />
            )}
            {!isEmpty(notVerifiedUsersData) &&
              isArray(notVerifiedUsersData) && (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Pagination
                    count={pagination?.totalPage}
                    page={page}
                    siblingCount={2}
                    onChange={(e, value) => {
                      setPageLoad(true);
                      setPageNo(value);
                      setHeaderParams(
                        value,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      getNotVerifiedUsersData(
                        value,
                        search,
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal
                      );
                    }}
                    sx={{ display: pageLoad ? "none" : "block" }}
                  />
                </div>
              )}
          </div>
        }
      />
      <CModal
        visible={dateModal}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModal(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  getNotVerifiedUsersData(
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate
                  );
                  setDateModal(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default NotVerifiedUsers;
