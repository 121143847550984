import {
  Grid,
  useMediaQuery,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MiniDrawer from "../../../Components/Drawer";
import BaseColor from "../../../Config/Color";
import theme, { FontFamily } from "../../../Config/theme";
import styles from "./style";
import CIcon from "../../../Components/CIcon";
import Files from "../../../Config/Files";
import CInput from "../../../Components/CInput";
import { BiUser } from "react-icons/bi";
import CSelect from "../../../Components/CSelect";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import CButton from "../../../Components/CButton";
import LinearProgressWithLabel from "@mui/material/LinearProgress";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BaseSetting from "../../../Apis/setting";
import { getApiData } from "../../../Apis/apiHelper";
import { toast } from "react-toastify";
import CModal from "../../../Components/CModal";
import _, { isEmpty } from "lodash";

const UserDetails = () => {
  // responsive size variable
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const classes = styles();

  const navigate = useNavigate();

  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [imageType, setImageType] = useState("");

  // form data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessDesc, setBusinessDesc] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");
  const [ZipPostalCode, setZipPostalCode] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetName, setStreetName] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [value, setValue] = useState("");

  const findFileType = (file) => {
    const liveImageSplit = _.isString(file)
      ? file.split(".")
      : !_.isEmpty(file) && _.isArray(file) && !_.isUndefined(file[0]?.url)
      ? file[0]?.url.split(".")
      : !_.isEmpty(file) && _.isObject(file) && !_.isUndefined(file?.url)
      ? file?.url.split(".")
      : "";

    const liveFileType = !_.isEmpty(liveImageSplit)
      ? liveImageSplit[liveImageSplit.length - 1]
      : !_.isEmpty(file) && _.isArray(file)
      ? file[0]?.type == "application/pdf"
        ? "pdf"
        : ""
      : "";

    return liveFileType;
  };

  const [verifyBtnLoad, setVerifyBtnLoad] = useState(false);

  const [languageList, setLanguageList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [pageLoad, setPageLoad] = useState(true);

  const [oneUserData, setOneUserData] = useState("");

  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const [rejectedReasons, setRejectedReason] = useState("");
  const [rejectedReasonTextError, setRejectedReasonTextError] = useState("");

  const [disableBtn, setDisableBtn] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [showImageModalOpen, setShowImageModalOpen] = useState(false);
  const [url, setUrl] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";

  const postStatus = searchParams.has("status")
    ? searchParams.get("status")
    : "";
  const user_id = searchParams.has("id") ? searchParams.get("id") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const setHeaderParams = (
    user_id,
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    post_status = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(user_id)) {
      params.id = user_id;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(post_status)) {
      params.status = post_status;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  const validation = () => {
    if (rejectedReasons.trim() === "") {
      setRejectedReasonTextError("Rejected reason is required");
    } else {
      setRejectedReasonTextError("");
      if (!_.isEmpty(imageType)) {
        rejectUser(true);
      } else {
        rejectUser();
      }
    }
  };
  useEffect(() => {
    GetLanguageListApi();
    GetCountryListApi();
    GetBusinessTypeListApi();
  }, []);

  useEffect(() => {
    setHeaderParams(
      user_id,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      downloadId
    );
  }, []);

  useEffect(() => {
    if (
      !isEmpty(languageList) &&
      !isEmpty(countryList) &&
      !isEmpty(businessTypeList)
    ) {
      getOneUserData();
    }
  }, [languageList, countryList, businessTypeList]);

  // get user data api
  const getOneUserData = (id) => {
    const endpoint = `${BaseSetting.endpoint.getOneUserProfileData}`;
    getApiData(endpoint, "post", { user_id: user_id })
      .then((result) => {
        if (result?.status) {
          const response = result?.data?.data;
          let res;
          response.map((data) => (res = data));
          setOneUserData(res);
          setData(res, result?.data?.files);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  //get language list
  const GetLanguageListApi = () => {
    const endpoint = BaseSetting.endpoint.languageList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setLanguageList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  //get country list api
  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // business list api
  const GetBusinessTypeListApi = () => {
    const endpoint = BaseSetting.endpoint.businessTypeList;
    const data = {
      type: "web",
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setBusinessTypeList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  // verify user api
  const verifyUser = () => {
    setSaveBtnLoading(true);
    const endpoint = BaseSetting.endpoint.verifyUserProfile;
    let filesType = [];
    if (!_.isEmpty(image1)) {
      filesType.push("company_document");
    }
    if (!_.isEmpty(image2)) {
      filesType.push("gst_document");
    }
    if (!_.isEmpty(image3)) {
      filesType.push("business_card");
    }
    if (!_.isEmpty(image4)) {
      filesType.push("other_document");
    }

    const data = {
      user_id: user_id,
      status: 1,
      filesType,
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          toast(result.message, { type: "success" });
          setSaveBtnLoading(false);
          navigate(
            `/verify-users?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
          );
        } else {
          toast(result.message, { type: "error" });
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setSaveBtnLoading(false);
      });
  };

  // reject user api
  const rejectUser = (bool = false) => {
    setVerifyBtnLoad(true);
    setDisableBtn(true);
    const endpoint = BaseSetting.endpoint.verifyUserProfile;
    let filesType = [];
    if (bool === true) {
      filesType = [imageType];
    } else {
      if (!_.isEmpty(image1)) {
        filesType.push("company_document");
      }
      if (!_.isEmpty(image2)) {
        filesType.push("gst_document");
      }
      if (!_.isEmpty(image3)) {
        filesType.push("business_card");
      }
      if (!_.isEmpty(image4)) {
        filesType.push("other_document");
      }
    }

    const data = {
      user_id: user_id,
      status: 0,
      rejected_reason: rejectedReasons,
      filesType,
    };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          toast(result.message, { type: "success" });
          setSaveBtnLoading(false);
          setRejectModalOpen(false);
          setDisableBtn(false);
          setRejectModalOpen(false);
          setImageType("");
          navigate(
            `/verify-users?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
          );
        } else {
          toast(result.message, { type: "error" });
          setSaveBtnLoading(false);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
        setSaveBtnLoading(false);
        setDisableBtn(false);
      });
  };

  //set user data
  const setData = (data, files = []) => {
    const userData =
      !_.isEmpty(data) && !_.isNull(data) && !_.isUndefined(data) ? data : {};

    const firstName =
      !_.isEmpty(data.first_name) &&
      !_.isNull(data.first_name) &&
      !_.isUndefined(data.first_name)
        ? data.first_name
        : {};

    const lastName =
      !_.isEmpty(data.last_name) &&
      !_.isNull(data.last_name) &&
      !_.isUndefined(data.last_name)
        ? data.last_name
        : {};

    const businessName =
      !_.isEmpty(data.name) && !_.isNull(data.name) && !_.isUndefined(data.name)
        ? data.name
        : {};

    const businessDescription =
      !_.isEmpty(data.description) &&
      !_.isNull(data.description) &&
      !_.isUndefined(data.description)
        ? data.description
        : {};
    const businessWeb =
      !_.isEmpty(data.website) &&
      !_.isNull(data.website) &&
      !_.isUndefined(data.website)
        ? data.website
        : "Not Available";

    const percentageValue =
      data.percentage > 0 &&
      !_.isNull(data.percentage) &&
      !_.isUndefined(data.percentage)
        ? data.percentage
        : 0;

    const languageValue =
      data.language_id > 0 &&
      !_.isNull(data.language_id) &&
      !_.isUndefined(data.language_id)
        ? languageList.find((v) => v.id === data.language_id)
        : "";

    const countryValue =
      !_.isNull(data.per_country_code) &&
      !_.isUndefined(data.per_country_code) &&
      !_.isEmpty(data?.per_country_code)
        ? countryList.find((v) => v.country_code === data.per_country_code)
        : "";

    const businessTypeValue =
      data.bus_type > 0 &&
      !_.isNull(data.bus_type) &&
      !_.isUndefined(data.bus_type)
        ? businessTypeList.find((v) => v.id === data.bus_type)
        : "";

    const landlineValue =
      !_.isEmpty(data.landline_number) &&
      !_.isNull(data.landline_number) &&
      !_.isUndefined(data.landline_number)
        ? data.landline_number
        : "";

    const phoneNumberValue =
      !_.isEmpty(data.phone) &&
      !_.isNull(data.phone) &&
      !_.isUndefined(data.phone)
        ? data.phone
        : "";

    const pinCodeValue =
      !_.isEmpty(data.zipcode) &&
      !_.isNull(data.zipcode) &&
      !_.isUndefined(data.zipcode)
        ? data.zipcode
        : "";
    const stateValue =
      !_.isEmpty(data.state) &&
      !_.isNull(data.state) &&
      !_.isUndefined(data.state)
        ? data.state
        : "";
    const cityValue =
      !_.isEmpty(data.city) && !_.isNull(data.city) && !_.isUndefined(data.city)
        ? data.city
        : "";
    const streetNameValue =
      !_.isEmpty(data.street_name) &&
      !_.isNull(data.street_name) &&
      !_.isUndefined(data.street_name)
        ? data.street_name
        : "";

    const businessCountryValue =
      !_.isNull(data.bus_country_code) &&
      !_.isUndefined(data.bus_country_code) &&
      !_.isEmpty(data?.bus_country_code)
        ? countryList.find((v) => v.country_code === data.bus_country_code)
        : "";

    if (!_.isEmpty(files)) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file?.type == "company_document") {
          setImage1(file?.url);
        }
        if (file?.type == "gst_document") {
          setImage2(file?.url);
        }
        if (file?.type == "business_card") {
          setImage3(file?.url);
        }
        if (file?.type == "other_document") {
          setImage4(file?.url);
        }
      }
    }

    setFirstName(firstName);
    setLastName(lastName);
    setBusinessName(businessName);
    setBusinessDesc(businessDescription);
    setBusinessWebsite(businessWeb);
    setValue(percentageValue);
    setLanguage(languageValue);
    setCountry(countryValue);
    setBusinessType(businessTypeValue);
    setLandlineNumber(landlineValue);
    setMobileNumber(phoneNumberValue);
    setZipPostalCode(pinCodeValue);
    setState(stateValue);
    setCity(cityValue);
    setStreetName(streetNameValue);
    setBusinessCountry(businessCountryValue);
    setPageLoad(false);
  };

  return (
    <Grid container>
      <MiniDrawer
        header="User Details"
        children={
          <>
            <Grid container>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(
                    `/verify-users?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}`
                  )
                }
              >
                <ArrowBackIcon /> Back
              </Button>
            </Grid>
            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <div style={{ backgroundColor: BaseColor.whiteColor }}>
                <Grid
                  container
                  marginBottom={"30px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item container xs={11} lg={12}>
                    <Grid
                      item
                      container
                      display={"flex"}
                      justifyContent={"center"}
                      mt={5}
                      gap={4}
                      wrap={md ? "wrap" : "nowrap"}
                    >
                      <Grid item xs={12} md={12}>
                        <Grid item container>
                          <Grid
                            item
                            container
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            wrap={"nowrap"}
                            gap={3}
                          >
                            <Grid item>
                              <img
                                alt=""
                                src={
                                  !isEmpty(oneUserData?.photo)
                                    ? oneUserData?.photo
                                    : Files?.images?.profile
                                }
                                className={classes.roundDiv}
                              />
                            </Grid>

                            <Grid item container>
                              <Grid
                                item
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems="center"
                                wrap={"nowrap"}
                                mb={1.5}
                              >
                                <span
                                  style={{
                                    fontFamily: FontFamily.SemiBold,
                                    color: BaseColor.primary,
                                    fontSize: "18px",
                                  }}
                                >
                                  Profile Strength (Average)
                                </span>

                                <span
                                  style={{
                                    fontFamily: FontFamily.SemiBold,
                                    color: BaseColor.primary,
                                  }}
                                >
                                  {value}%
                                </span>
                              </Grid>
                              <Grid item xs={12}>
                                <LinearProgressWithLabel
                                  variant="buffer"
                                  value={value}
                                  style={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              mt={5}
                            >
                              <span className={classes.header}>
                                Personal information
                              </span>
                            </Grid>
                            <Grid
                              container
                              sx={{
                                backgroundColor: BaseColor.offWhite,
                                padding: "15px",
                                marginTop: "20px",
                              }}
                              gap={2}
                            >
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="First name"
                                  readOnly={true}
                                  value={firstName}
                                  onChange={(val) => setFirstName(val)}
                                  startIcon={
                                    <BiUser className="CInputStartIcon" />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Last name"
                                  readOnly={true}
                                  value={lastName}
                                  onChange={(val) => setLastName(val)}
                                  startIcon={
                                    <BiUser className="CInputStartIcon" />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CSelect
                                  data={languageList}
                                  placeholder="Select language"
                                  value={language}
                                  fullObj
                                  readOnly={true}
                                  onSelect={(val) => {
                                    setLanguage(val);
                                  }}
                                  svgIcon={Files.svgIcons.translate}
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CSelect
                                  country
                                  data={countryList}
                                  fullObj
                                  placeholder="Select country"
                                  value={country}
                                  readOnly={true}
                                  onSelect={(val) => {
                                    setCountry(val);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              marginTop={"20px"}
                              marginBottom={"20px"}
                            >
                              <span className={classes.header}>
                                Business information
                              </span>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={12}
                              sx={{
                                backgroundColor: BaseColor.offWhite,
                                padding: "15px",
                              }}
                              gap={2}
                            >
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Business name"
                                  value={businessName}
                                  onChange={(val) => setBusinessName(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.work}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CSelect
                                  data={businessTypeList}
                                  fullObj
                                  placeholder="Business Type"
                                  value={businessType}
                                  readOnly={true}
                                  onSelect={(val) => {
                                    setBusinessType(val);
                                  }}
                                  svgIcon={Files.svgIcons.work}
                                />
                              </Grid>
                              <Grid item xs={12} sm={11.8}>
                                <CInput
                                  placeholder="Business Description"
                                  value={businessDesc}
                                  onChange={(val) => setBusinessDesc(val)}
                                  readOnly={true}
                                  multiline
                                  rows={6}
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Business website"
                                  value={businessWebsite}
                                  onChange={(val) => setBusinessWebsite(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.work}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Mobile number"
                                  value={mobileNumber}
                                  onChange={(val) => setMobileNumber(val)}
                                  readOnly={true}
                                  startIcon={
                                    <HiOutlineDevicePhoneMobile className="CInputStartIcon" />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Landline number"
                                  value={landlineNumber}
                                  onChange={(val) => setLandlineNumber(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.telePhone}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Zip/postal code"
                                  value={ZipPostalCode}
                                  onChange={(val) => {
                                    setZipPostalCode(val);
                                  }}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.pinCode}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CSelect
                                  country
                                  data={countryList}
                                  fullObj
                                  placeholder="Select country"
                                  value={businessCountry}
                                  readOnly={true}
                                  onSelect={(val) => {
                                    setBusinessCountry(val);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="State"
                                  value={state}
                                  onChange={(val) => setState(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.location}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="City"
                                  value={city}
                                  onChange={(val) => setCity(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.location}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={5.8}>
                                <CInput
                                  placeholder="Street name"
                                  value={streetName}
                                  onChange={(val) => setStreetName(val)}
                                  readOnly={true}
                                  startIcon={
                                    <CIcon
                                      src={Files.svgIcons.location}
                                      className="CInputStartIcon"
                                    />
                                  }
                                />
                              </Grid>

                              <Grid item container>
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 10 }}
                                  justifyContent={"space-between"}
                                  alignItems="center"
                                  gap={2}
                                >
                                  <Grid
                                    item
                                    display={"flex"}
                                    flexDirection={"column"}
                                    textAlign={"center"}
                                    alignItems="center"
                                  >
                                    <p className={classes.imgUploadHeader}>
                                      Company Reg. Document
                                    </p>
                                    <div className={classes.imageContainer}>
                                      {!isEmpty(image1) ? (
                                        findFileType(image1) == "pdf" ? (
                                          <img
                                            src={Files?.images?.pdfImage}
                                            alt="uploadImage"
                                            onClick={() => {
                                              setUrl(image1);
                                              setShowImageModalOpen(true);
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={image1}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "fill",
                                            }}
                                            onClick={() => {
                                              setUrl(image1);
                                              setShowImageModalOpen(true);
                                            }}
                                          ></img>
                                        )
                                      ) : (
                                        <span>No Data</span>
                                      )}
                                    </div>
                                    {!isEmpty(image1) ? (
                                      <CButton
                                        variant="outlined"
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          setRejectModalOpen(true);
                                          setImageType("company_document");
                                        }}
                                      >
                                        <Typography className={classes.textBtn}>
                                          Reject document
                                        </Typography>
                                      </CButton>
                                    ) : null}
                                  </Grid>
                                  <Grid
                                    item
                                    display={"flex"}
                                    flexDirection={"column"}
                                    textAlign={"center"}
                                    alignItems="center"
                                  >
                                    <p className={classes.imgUploadHeader}>
                                      GST/VAT Document
                                    </p>
                                    <div className={classes.imageContainer}>
                                      {!isEmpty(image2) ? (
                                        findFileType(image2) == "pdf" ? (
                                          <img
                                            src={Files?.images?.pdfImage}
                                            alt="uploadImage"
                                            onClick={() => {
                                              setUrl(image2);
                                              setShowImageModalOpen(true);
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={image2}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "fill",
                                            }}
                                            onClick={() => {
                                              setUrl(image2);
                                              setShowImageModalOpen(true);
                                            }}
                                          ></img>
                                        )
                                      ) : (
                                        <span>No Data</span>
                                      )}
                                    </div>
                                    {!isEmpty(image2) ? (
                                      <CButton
                                        variant="outlined"
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          setRejectModalOpen(true);
                                          setImageType("gst_document");
                                        }}
                                      >
                                        <Typography className={classes.textBtn}>
                                          Reject document
                                        </Typography>
                                      </CButton>
                                    ) : null}
                                  </Grid>
                                  <Grid
                                    item
                                    display={"flex"}
                                    flexDirection={"column"}
                                    textAlign={"center"}
                                    alignItems="center"
                                  >
                                    <p className={classes.imgUploadHeader}>
                                      Company Business card
                                    </p>
                                    <div className={classes.imageContainer}>
                                      {!isEmpty(image3) ? (
                                        findFileType(image3) == "pdf" ? (
                                          <img
                                            src={Files?.images?.pdfImage}
                                            alt="uploadImage"
                                            onClick={() => {
                                              setUrl(image3);
                                              setShowImageModalOpen(true);
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={image3}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "fill",
                                            }}
                                            onClick={() => {
                                              setUrl(image3);
                                              setShowImageModalOpen(true);
                                            }}
                                          ></img>
                                        )
                                      ) : (
                                        <span>No Data</span>
                                      )}
                                    </div>
                                    {!isEmpty(image3) ? (
                                      <CButton
                                        variant="outlined"
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          setRejectModalOpen(true);
                                          setImageType("business_card");
                                        }}
                                      >
                                        <Typography className={classes.textBtn}>
                                          Reject document
                                        </Typography>
                                      </CButton>
                                    ) : null}
                                  </Grid>
                                  <Grid
                                    item
                                    display={"flex"}
                                    flexDirection={"column"}
                                    textAlign={"center"}
                                    alignItems="center"
                                  >
                                    <p className={classes.imgUploadHeader}>
                                      Other
                                    </p>
                                    <div className={classes.imageContainer}>
                                      {!isEmpty(image4) ? (
                                        findFileType(image4) == "pdf" ? (
                                          <img
                                            src={Files?.images?.pdfImage}
                                            alt="uploadImage"
                                            onClick={() => {
                                              setUrl(image4);
                                              setShowImageModalOpen(true);
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={image4}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "fill",
                                            }}
                                            onClick={() => {
                                              setUrl(image4);
                                              setShowImageModalOpen(true);
                                            }}
                                          ></img>
                                        )
                                      ) : (
                                        <span>No Data</span>
                                      )}
                                    </div>
                                    {!isEmpty(image4) ? (
                                      <CButton
                                        variant="outlined"
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          setRejectModalOpen(true);
                                          setImageType("other_document");
                                        }}
                                      >
                                        <Typography className={classes.textBtn}>
                                          Reject document
                                        </Typography>
                                      </CButton>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={3}>
                              <CButton
                                style={{ marginTop: "10px" }}
                                onClick={() => verifyUser()}
                                variant="contained"
                                loading={verifyBtnLoad}
                              >
                                Verify
                              </CButton>
                            </Grid>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={3}>
                              <CButton
                                style={{ marginTop: "10px" }}
                                onClick={() => setRejectModalOpen(true)}
                                variant="contained"
                              >
                                Reject
                              </CButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={rejectModalOpen}
        style={{ width: "30%" }}
        title="Reject Profile"
        onClose={() => {
          setRejectModalOpen(false);
          setImageType("");
          setRejectedReason("");
        }}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter rejected reasons"
                  onChange={(val) => setRejectedReason(val)}
                  value={rejectedReasons}
                  style={{ width: "100%" }}
                  errorMsg={rejectedReasonTextError}
                />
              </Grid>
              <Grid item xs={6}>
                <CButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={() => validation()}
                  disabled={disableBtn}
                  loading={saveBtnLoading}
                >
                  Save
                </CButton>
              </Grid>
              <Grid item xs={6} paddingLeft={"10px"}>
                <Button
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setRejectModalOpen(false);
                    setBusinessType("");
                    setRejectedReasonTextError("");
                    setImageType("");
                    setRejectedReason("");
                  }}
                  disabled={disableBtn}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />
      <CModal
        visible={showImageModalOpen}
        style={{ width: "40%" }}
        onClose={() => {
          setShowImageModalOpen(false);
          setUrl("");
        }}
        title={"Preview document"}
        children={
          <div style={{ padding: "20px" }}>
            {findFileType(url) == "pdf" ? (
              <iframe
                src={url}
                title="iframe"
                height={"700"}
                width="100%"
              ></iframe>
            ) : (
              <img src={url} style={{ width: "100%", height: "100%" }} />
            )}
          </div>
        }
      />
    </Grid>
  );
};

export default UserDetails;
