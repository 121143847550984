import React, { useEffect, useState, useRef } from "react";
import MiniDrawer from "../../Components/Drawer";
import {
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
  Tooltip,
  useMediaQuery,
  Box,
  Chip,
  Divider,
} from "@mui/material";
import { getApiData } from "../../Apis/apiHelper";
import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BaseColor from "../../Config/Color";
import theme, { FontFamily } from "../../Config/theme";
import CSelect from "../../Components/CSelect";
import { downloadOptions, shortcutsItems } from "../../Config/staticData";
import jsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CModal from "../../Components/CModal";
import UnpublishedIcon from "@mui/icons-material/UnpublishedOutlined";
import CButton from "../../Components/CButton";
import _, { isArray, isEmpty, isNull } from "lodash";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import styles from "./styles";

const ActiveUsers = () => {
  const searchRef = useRef();
  const classes = styles();
  const [pageLoad, setPageLoad] = useState(false);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  //pagination
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [yesBtnLoading, setYesBtnLoading] = useState(false);
  const [id, setId] = useState("");
  const [dataLoader, setDataLoader] = useState(false);

  // sort an search
  const [searchVal, setSearchVal] = useState("");
  const [rowChange, setRowChange] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userType, setuserType] = useState(
    searchParams.get("user_type") === "active"
      ? {
          id: "active",
          name: "Active",
        }
      : searchParams.get("user_type") === "verified"
      ? {
          id: "verified",
          name: "Verified",
        }
      : searchParams.get("user_type") === "deleted_users"
      ? {
          id: "deleted_users",
          name: "Deleted users",
        }
      : {
          id: "in_active",
          name: "Inactive",
        }
  );

  // get params from URL params
  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";
  const selectedUserType = searchParams.has("user_type")
    ? searchParams.get("user_type")
    : userType?.id;
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];
  const downloadIdArr = searchParams.has("download_id")
    ? searchParams
        .get("download_id")
        ?.split(",")
        .map((str) => parseInt(str, 10))
    : [];
  const dataGridRef = useRef();
  const getDataGridApi = (api) => {
    dataGridRef.current = api;
  };
  const [selIds, setselIds] = useState(downloadIdArr);

  // date filter
  const [dateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setHeaderParams(
      selectedUserType,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
    setTimeout(() => {
      setRowChange(true);
    }, 500);
  }, []);

  // set data to URL params
  const setHeaderParams = (
    selectedUserType,
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    download_id = []
  ) => {
    const params = {
      page: page,
    };

    setPageNo(page);
    if (!_.isEmpty(selectedUserType)) {
      params.user_type = selectedUserType;
    }
    if (!_.isEmpty(search)) {
      setSearchVal(search?.trim());
    }
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    setSearchParams(createSearchParams(params));
  };

  //activeUserData
  const [activeUserData, setActiveUserData] = useState([]);
  const [selectFileType, setSelectFileType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "No",
    "First name",
    "Last name",
    "Email",
    "Phone",
    "Language",
    "Business name",
    "Business website",
    "Landline number",
    "Pincode",
    "City",
    "Platform type",
    "Created at",
  ]);

  useEffect(() => {
    setPageLoad(true);
    getActiveUserData(
      selectedUserType,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      downloadId
    );
  }, [userType]);

  //User list api call
  async function getActiveUserData(
    selectedUserType,
    page,
    search,
    sort,
    sortBy,
    startDate,
    endDate
  ) {
    const data = {
      page: page,
      type: "admin",
      status: selectedUserType,
    };

    if (!_.isEmpty(search)) {
      data.searchVal = search?.trim();
    }

    if (!_.isEmpty(sort)) {
      data.sort = sort;
    }

    if (!_.isEmpty(sortBy)) {
      data.sort_by = sortBy;
    }

    if (!_.isEmpty(startDate)) {
      data.start_date = startDate;
    }

    if (!_.isEmpty(endDate)) {
      data.end_date = endDate;
    }
    const endpoint = `${BaseSetting.endpoint.activeUsers}`;
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setActiveUserData(response?.users);
          setPagination(response?.pagination);
          toast(result?.message, { type: "success" });
          setTimeout(() => {
            setDataLoader(false);
            setPageLoad(false);
          }, 100);
        } else {
          toast(result?.message, { type: "error" });
          setDataLoader(false);
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setDataLoader(false);
        setPageLoad(false);
      });
  }

  const userTypeData = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "in_active",
      name: "Inactive",
    },
    {
      id: "verified",
      name: "Verified",
    },
    {
      id: "deleted_users",
      name: "Deleted users",
    },
  ];

  // Remove verified users
  const RemovedVerifiedUser = () => {
    setYesBtnLoading(true);
    getApiData(BaseSetting.endpoint.RemoveUserVerificaion, "post", {
      user_id: id,
    })
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          toast(result?.message, { type: "success" });
          setYesBtnLoading(false);
          setConfirm(false);
          setId("");
          getActiveUserData(selectedUserType, pageNo, true);
        } else {
          toast(result?.message, { type: "error" });
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setYesBtnLoading(false);
      });
  };

  //download data
  const downloadAsPdf = (data) => {
    const pdf = new jsPDF({
      marginLeft: 10,
      marginRight: 10,
      format: "a4",
    });
    const tableData = data;
    const headers = visibleColumns;
    const rows = [];

    const headerStyle = {
      fillColor: BaseColor.primary,
    };

    tableData.forEach((rowData) => {
      const row = [];
      for (const key in rowData) {
        row.push(rowData[key]);
      }
      rows.push(row);
    });
    pdf.setFont("Arial", "normal");
    pdf.setFontSize(12);
    const pageWidth = pdf.internal.pageSize.getWidth() - 20;
    const columnWidth = pageWidth / headers.length - 1;
    const marginLeft = 13;
    pdf.autoTable({
      head: [headers],
      headerStyle: headerStyle,
      body: rows,
      columnStyles: headers.reduce((acc, header, index) => {
        acc[index] = { cellWidth: columnWidth };
        return acc;
      }, {}),
      margin: { left: marginLeft },
    });
    pdf.save(`${userType.name}-Data.pdf`);
    setSelectFileType("");
  };

  const handleDownloadCSV = async (data) => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${userType.name}-Data.csv`;
    link.click();
    URL.revokeObjectURL(url);
    setSelectFileType("");
  };

  const handleDownloadExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${userType.name}-Data.xlsx`);
      setSelectFileType("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Download data api call
  function getDownloadData(type) {
    let data = {
      download: true,
      page: 1,
      type: "admin",
      status: userType.id,
      download_id: selIds,
    };
    getApiData(BaseSetting.endpoint.activeUsers, "post", data)
      .then((result) => {
        if (result?.status) {
          const response = result?.data;

          let dataSort = [];
          response.map((val, index) => {
            const createdAtTimestamp = new Date(val?.createdAt);
            const options = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            const formattedLoginAt = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(createdAtTimestamp);
            let newObj = {
              No: index + 1,
              "First name": val.first_name,
              "Last name": val.last_name,
              Email: val.email,
              Phone: val?.phone,
              Language: val?.language,
              "Business name": val?.name,
              "Business website": val?.website,
              "Landline number": val?.landline_number,
              Pincode: val?.zipcode,
              City: val?.city,
              "Platform type": val?.plateform_type,
              "Created at": formattedLoginAt,
            };
            dataSort.push(newObj);
          });
          if (type === "Download as PDF") {
            downloadAsPdf(dataSort);
          } else if (type === "Download as CSV") {
            handleDownloadCSV(dataSort);
          } else {
            handleDownloadExcel(dataSort);
          }
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
      });
  }

  const tableColumns = [
    {
      field: "id",
      headerName: "NO.",
      flex: 0.2,
      headerAlign: "center",
      minWidth: 70,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const newStartIndex = (pageNo - 1) * pagination?.defaultPageSize + 1;
        activeUserData.map((data, i) => {
          data.serialNumber = i + newStartIndex;
        });
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {params?.row?.serialNumber}
          </Typography>
        );
      },
    },
    userType.id === "verified"
      ? {
          field: "actions",
          headerName: "ACTIONS",
          minWidth: 200,
          flex: 0.5,
          headerAlign: "center",
          align: "center",
          sortable: false,
          filterable: false,
          renderCell: (params) => (
            <Tooltip title="Remove Verification" placement="top" arrow>
              <Button
                onClick={() => {
                  setConfirm(true);
                  setId(params?.row?.id);
                }}
              >
                <UnpublishedIcon
                  style={{ color: BaseColor.red, fontSize: 25 }}
                />
              </Button>
            </Tooltip>
          ),
        }
      : null,
    {
      field: "first_name",
      headerName: "FIRST NAME",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 200,
      flex: 1.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      minWidth: 200,
      headerName: "PHONE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "language",
      headerName: "LANGUAGE",
      width: 180,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "name",
      headerName: "BUSINESS NAME",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "website",
      headerName: "BUSINESS WEBSITE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "landline_number",
      headerName: "LANDLINE NUMBER",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "zipcode",
      headerName: "PINCODE",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "city",
      headerName: "CITY",
      width: 220,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "plateform_type",
      minWidth: 200,
      headerName: "PLATFORM TYPE",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "status",
    //   minWidth: 200,
    //   headerName: "STATUS",
    //   flex: 0.4,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //       <span>
    //         {params?.row?.status === "1"
    //           ? "Active"
    //           : params?.row?.status === "2"
    //           ? "Verified"
    //           : params?.row?.status === "3"
    //           ? "Pending"
    //           : params?.row?.status === "-1"
    //           ? "Remove"
    //           : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const createdAtTimestamp = new Date(params.row.createdAt);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        const formattedLoginAt = new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(createdAtTimestamp);
        return (
          <Typography style={{ fontFamily: FontFamily.SemiBold }}>
            {formattedLoginAt}
          </Typography>
        );
      },
    },
    // userType.id === "verified"
    //   ? {
    //       field: "actions",
    //       headerName: "ACTIONS",
    //       minWidth: 200,
    //       flex: 0.5,
    //       headerAlign: "center",
    //       align: "center",
    //       sortable: false,
    //       filterable: false,
    //       renderCell: (params) => (
    //         <Tooltip title="Remove Verification" placement="top" arrow>
    //           <Button
    //             onClick={() => {
    //               setConfirm(true);
    //               setId(params?.row?.id);
    //             }}
    //           >
    //             <UnpublishedIcon
    //               style={{ color: BaseColor.red, fontSize: 25 }}
    //             />
    //           </Button>
    //         </Tooltip>
    //       ),
    //     }
    //   : null,
  ].filter(Boolean);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  function CustomRangeShortcuts(props) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        className={classes.scrollBar}
        sx={{
          gridRow: 1,
          gridColumn: 2,
          // overflow: "auto",
          maxWidth: "100%",
          marginLeft: "0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          gap={1}
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: theme.spacing(4),
            "& .MuiListItem-root": {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <Typography key={item.label}>
                <Chip {...item} />
              </Typography>
            );
          })}
        </Grid>
        <Divider />
      </Box>
    );
  }

  return (
    <Grid container>
      <MiniDrawer
        header="Users"
        children={
          <>
            <Grid container justifyContent={"space-between"} mb={"30px"}>
              <Grid item xs={3}>
                <CSelect
                  fullObj
                  data={userTypeData}
                  placeholder="Select user type"
                  value={userTypeData?.find(
                    (data) => data?.id === selectedUserType
                  )}
                  onSelect={(val) => {
                    setuserType(val);
                    setselIds([]);
                    setSearchVal("");
                    setHeaderParams(val?.id, 1, "", "", "", "", "", []);
                  }}
                />
              </Grid>
              <div style={{ display: "flex" }}>
                {!_.isEmpty(sortVal) ||
                !_.isEmpty(search) ||
                !_.isEmpty(sortByVal) ||
                !_.isEmpty(downloadId) ||
                !_.isEmpty(selIds) ||
                !_.isEmpty(startDateVal) ||
                !_.isEmpty(endDateVal) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHeaderParams(
                        selectedUserType,
                        1,
                        "",
                        "",
                        "",
                        "",
                        "",
                        []
                      );
                      getActiveUserData(selectedUserType, 1);
                      setSearchVal("");
                      setDataLoader(true);
                      setselIds([]);
                      setStartDate("");
                      setEndDate("");
                      if (dataGridRef.current) {
                        dataGridRef.current.setFilterModel({
                          items: [],
                          quickFilter: "",
                        });
                      }
                    }}
                  >
                    Clear filters
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  onClick={() => setDateModal(true)}
                  style={{ marginLeft: "10px" }}
                >
                  Filter date
                </Button>
                <CSelect
                  style={{ marginLeft: "20px" }}
                  placeholder="Choose file format for download"
                  value={selectFileType}
                  data={downloadOptions}
                  onSelect={(val) => {
                    setSelectFileType(val);
                    getDownloadData(val);
                  }}
                />
              </div>
            </Grid>

            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress
                  style={{ color: BaseColor.primary }}
                  size={30}
                />
              </div>
            ) : (
              <DataGrid
                sortModel={
                  sortByVal === "asc" || sortByVal === "desc"
                    ? [
                        {
                          field: sortVal,
                          sort: sortByVal,
                        },
                      ]
                    : []
                }
                onSortModelChange={(model) => {
                  setDataLoader(true);
                  if (!_.isEmpty(model)) {
                    setHeaderParams(
                      selectedUserType,
                      isPage,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getActiveUserData(
                      selectedUserType,
                      page,
                      search,
                      model[0]?.field,
                      model[0].sort,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                  } else {
                    setHeaderParams(
                      selectedUserType,
                      isPage,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getActiveUserData(
                      selectedUserType,
                      page,
                      search,
                      "",
                      "",
                      startDateVal,
                      endDateVal
                    );
                  }
                }}
                rows={activeUserData}
                columns={tableColumns}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                loading={dataLoader}
                hideFooter={isEmpty(selIds)}
                hideFooterPagination
                disableRowSelectionOnClick
                sortingMode="server"
                apiRef={getDataGridApi}
                checkboxSelection
                rowSelectionModel={selIds}
                keepNonExistentRowsSelected
                paginationMode="server"
                onRowSelectionModelChange={(id) => {
                  const downloadIdString = id.join(",");
                  setselIds(id);
                  if (rowChange === true) {
                    setHeaderParams(
                      selectedUserType,
                      page,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadIdString
                    );
                  }
                }}
                disableDensitySelector
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                disableSelectionOnClick
                autoHeight={true}
                getRowHeight={() => "auto"}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [searchVal],
                    },
                  },
                }}
                slotProps={{
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    onChange: (e) => {
                      setDataLoader(true);
                      setSearchVal(e.target.value?.toLowerCase());
                      setHeaderParams(
                        selectedUserType,
                        1,
                        e?.target?.value?.toLowerCase(),
                        sortVal,
                        sortByVal,
                        startDateVal,
                        endDateVal,
                        downloadId
                      );
                      if (searchRef.current) clearTimeout(searchRef.current);
                      searchRef.current = setTimeout(() => {
                        getActiveUserData(
                          selectedUserType,
                          1,
                          e?.target?.value?.toLowerCase(),
                          sortVal,
                          sortByVal,
                          startDateVal,
                          endDateVal
                        );
                      }, 800);
                    },
                  },
                }}
              />
            )}
            {!isEmpty(userTypeData) && isArray(userTypeData) && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={pagination?.totalPage}
                  page={page}
                  siblingCount={2}
                  onChange={(e, value) => {
                    setPageLoad(true);
                    setPageNo(value);
                    setHeaderParams(
                      selectedUserType,
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                    getActiveUserData(
                      selectedUserType,
                      value,
                      search,
                      sortVal,
                      sortByVal,
                      startDateVal,
                      endDateVal,
                      downloadId
                    );
                  }}
                  sx={{ display: pageLoad ? "none" : "block" }}
                />
              </div>
            )}
          </>
        }
      />
      <CModal
        visible={confirm}
        onClose={() => {
          setConfirm(false);
        }}
        title={"Remove Verification"}
        children={
          <>
            <Grid container padding={"30px 0px"}>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 15,
                    fontFamily: FontFamily.Medium,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Are you sure, you want to remove verification of this user ?
                </Typography>
              </Grid>
              <Grid container justifyContent={"center"} gap={2} wrap="nowrap">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setConfirm(false);
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <CButton
                    onClick={() => RemovedVerifiedUser()}
                    fullWidth
                    variant="contained"
                    loading={yesBtnLoading}
                  >
                    Yes
                  </CButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <CModal
        visible={dateModal}
        style={{ width: md ? "60%" : sm ? "60%" : lg ? "50%" : "30%" }}
        onClose={() => {
          setDateModal(false);
        }}
        title={"Filter Date"}
        children={
          <div style={{ padding: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                disableFuture
                switchViewButton
                slots={{
                  shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                onChange={(val) => {
                  if (isNull(val[0]) && isNull(val[1])) {
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(formatDate(val[0]?.$d));
                    setEndDate(formatDate(val[1]?.$d));
                  }
                }}
                calendars={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setPageLoad(true);
                  setHeaderParams(
                    selectedUserType,
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  getActiveUserData(
                    selectedUserType,
                    1,
                    search,
                    sortVal,
                    sortByVal,
                    startDate,
                    endDate,
                    downloadId
                  );
                  setDateModal(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        }
      />
    </Grid>
  );
};

export default ActiveUsers;
