import React, { useEffect, useRef, useState } from "react";
import styles from "./style";
import Carousel from "react-material-ui-carousel";
import BaseColor from "../../../Config/Color";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Files from "../../../Config/Files";
import CButton from "../../../Components/CButton/index";
import { getApiData } from "../../../Apis/apiHelper";
import BaseSetting from "../../../Apis/setting";
import _, { isEmpty, isNull } from "lodash";
import { toast } from "react-toastify";
import ReactCountryFlag from "react-country-flag";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CIcon from "../../../Components/CIcon";
import theme, { FontFamily } from "../../../Config/theme";
import moment from "moment";
import CModal from "../../../Components/CModal";
import CInput from "../../../Components/CInput";
import MiniDrawer from "../../../Components/Drawer";
import CPreviewModal from "../../../Components/CPreviewModal";
const ProductDetail = (props) => {
  const navigate = useNavigate();
  const classes = styles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const postId = state?.postId;
  const [pageLoad, setPageLoad] = useState(true);
  const [list, setList] = useState({});
  const [pid, setPid] = useState(postId);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [postImages, setPostImages] = useState([]);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectText, setRejectText] = useState("");
  const [rejectErrorText, setRejectErrorText] = useState("");
  const previewModalRef = useRef();

  // mediaQuery used for responsive
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));

  // select a sub image array state
  const [activeImg, setActiveImg] = useState(0);
  const [checked, setChecked] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  const [readMore, setReadMore] = useState(true);

  const [oneDraftData, setOneDraftData] = useState();

  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false);

  const [disableBtn, setDisableBtn] = useState(false);

  // const [buyEditBtnLoader, setBuyEditBtnLoader] = useState(false);
  const [postIdValue, setPostIdValue] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    GetCountryListApi();
    GetCurrencyListApi();
  }, []);

  useEffect(() => {
    setHeaderParams(
      post_id,
      page,
      search,
      sortVal,
      sortByVal,
      startDateVal,
      endDateVal,
      postStatus,
      downloadId,
      createdPost
    );
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const isPage = searchParams.get("page");
  const page = isPage > 1 ? Number(isPage) : 1;
  const search = searchParams.has("search") ? searchParams.get("search") : "";
  const sortVal = searchParams.has("sort") ? searchParams.get("sort") : "";

  const postStatus = searchParams.has("status")
    ? searchParams.get("status")
    : "";
  const post_id = searchParams.has("id") ? searchParams.get("id") : "";
  const sortByVal = searchParams.has("sort_by")
    ? searchParams.get("sort_by")
    : "";
  const startDateVal = searchParams.has("start_date")
    ? searchParams.get("start_date")
    : "";
  const endDateVal = searchParams.has("end_date")
    ? searchParams.get("end_date")
    : "";
  const downloadId = searchParams.has("download_id")
    ? searchParams.get("download_id")
    : [];

  const createdPost = searchParams.has("created_by")
    ? searchParams.get("created_by")
    : "";

  const setHeaderParams = (
    id,
    page,
    search = "",
    sort = "",
    sortBy = "",
    start_date = "",
    end_date = "",
    post_status = "",
    download_id = [],
    createdPost
  ) => {
    const params = {
      page: page,
    };
    if (!_.isEmpty(search)) {
      params.search = search;
    }
    if (!_.isEmpty(id)) {
      params.id = id;
    }
    if (!_.isEmpty(sort)) {
      params.sort = sort;
    }
    if (!_.isEmpty(sortBy)) {
      params.sort_by = sortBy;
    }

    if (!_.isEmpty(start_date)) {
      params.start_date = start_date;
    }
    if (!_.isEmpty(end_date)) {
      params.end_date = end_date;
    }
    if (!_.isEmpty(post_status)) {
      params.status = post_status;
    }
    if (!_.isEmpty(download_id)) {
      params.download_id = download_id;
    }
    if (!_.isEmpty(createdPost)) {
      params.created_by = createdPost;
    }
    setSearchParams(createSearchParams(params));
  };

  // get currency list
  const GetCurrencyListApi = () => {
    const endpoint = BaseSetting.endpoint.currencyList;
    getApiData(endpoint, "post", { type: "main" })
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCurrencyList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  useEffect(() => {
    const isSuccess = !_.isEmpty(countryList) && _.isArray(countryList);
    if (isSuccess) {
      setReadMore(true);
      window.scrollTo(0, 0);
      getOneDraftData();
    }
  }, [countryList, pid]);

  const description = list?.description;
  let formattedDescription = "";

  if (description) {
    for (let i = 0; i < description.length; i += 35) {
      const chunk = description.slice(i, i + 35);
      formattedDescription += chunk + "\n";
    }
  }
  const allErrorFalse = () => {
    setRejectErrorText("");
  };

  const productId =
    !_.isUndefined(list?.post_details?.post_id) &&
    !_.isNull(list?.post_details?.post_id) &&
    !_.isEmpty(list?.post_details?.post_id)
      ? list?.post_details?.post_id
      : "";

  const postUserId =
    list?.userData?.personal_info?.id > 0 &&
    !_.isUndefined(list?.userData?.personal_info?.id) &&
    !_.isNull(list?.userData?.personal_info?.id)
      ? list?.userData?.personal_info?.id
      : null;

  const userName =
    !_.isEmpty(list) &&
    !_.isEmpty(list?.user_info) &&
    !_.isEmpty(list?.user_info?.personal_info) &&
    !_.isEmpty(list?.user_info?.personal_info?.first_name) &&
    !_.isEmpty(list?.user_info?.personal_info?.last_name)
      ? `${list?.user_info?.personal_info?.first_name} ${list?.user_info?.personal_info?.last_name}`
      : "";

  //get draft one data
  const getOneDraftData = (id) => {
    setPageLoad(true);
    const endpoint = `${BaseSetting.endpoint.oneDraftData}/${post_id}`;
    getApiData(endpoint, "GET", {})
      .then((result) => {
        if (result?.status) {
          const response = result?.data;
          setOneDraftData(response);
          setPostImages(response?.images);
          setPostIdValue(response?.post_details?.id);

          const findCountryCode = countryList.find(
            (v) => v.name == result?.data?.post_details?.country
          );
          setSelectedCountry(findCountryCode);
          setList(response);
          setPageLoad(false);
        } else {
          toast(result?.message, { type: "error" });
          setPageLoad(false);
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        setPageLoad(false);
      });
  };

  //change post status
  const changePostStatus = (type) => {
    if (type === "posted") {
      setApproveBtnLoading(true);
      setDisableBtn(true);
    } else {
      setRejectBtnLoading(true);
      setDisableBtn(true);
    }
    const endpoint = `${BaseSetting.endpoint.changeDraftPostStatus}`;
    getApiData(endpoint, "post", {
      id: post_id,
      status: type,
      reject_reason: rejectText,
    })
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          navigate(
            `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
          );
          if (type === "posted") {
            setApproveBtnLoading(false);
            setDisableBtn(false);
          } else {
            setRejectBtnLoading(false);
            setDisableBtn(false);
          }
        } else {
          toast(result?.message, { type: "error" });
          if (type === "posted") {
            setApproveBtnLoading(false);
            setDisableBtn(false);
          } else {
            setRejectBtnLoading(false);
            setDisableBtn(false);
          }
        }
      })
      .catch((err) => {
        toast(err?.message, { type: "error" });
        if (type === "posted") {
          setApproveBtnLoading(false);
          setDisableBtn(false);
        } else {
          setRejectBtnLoading(false);
          setDisableBtn(false);
        }
      });
  };

  const createRejectValidation = () => {
    if (rejectText?.trim() === "" || rejectText === undefined) {
      allErrorFalse();
      setRejectErrorText(" Reject reason is required");
    } else {
      CheckIsImageCompressApi(postIdValue, "rejected", rejectText);
      allErrorFalse();
      setRejectText("");
      setRejectModal(false);
    }
  };

  // api call for get post details
  // async function GetPostDetailApi(bool) {
  //   bool && setPageLoad(true);
  //   const data = {
  //     post_id: pid,
  //     // user_id: userId,
  //   };
  //   getApiData(
  //     BaseSetting.endpoint.getpostdetails,
  //     "POST",
  //     loginRequired ? {} : data
  //   )
  //     .then((result) => {
  //       if (result?.status) {
  //         if (!isEmpty(result?.data)) {
  //           setList(result?.data);
  //           setPostImages(result?.data?.post_images);
  //           const findCountryCode = countryList.find(
  //             (v) => v.name == result?.data?.post_details?.country
  //           );
  //           const postImageList = result?.data?.post_images;
  //           const findMainImageIndex =
  //             !_.isEmpty(postImageList) && _.isArray(postImageList)
  //               ? postImageList?.findIndex((v) => v?.main_image == 1)
  //               : -1;
  //           setActiveImg(findMainImageIndex);
  //           setSelectedCountry(findCountryCode);
  //           if (
  //             isArray(result?.data?.categoryRelatedPosts) &&
  //             !isEmpty(result?.data?.categoryRelatedPosts)
  //           ) {
  //             setCategoryPostList(result?.data?.categoryRelatedPosts);
  //           }
  //           if (
  //             isArray(result?.data?.userRelatedPosts) &&
  //             !isEmpty(result?.data?.userRelatedPosts)
  //           ) {
  //             setUserPostList(result?.data?.userRelatedPosts);
  //           }
  //         }
  //         setPageLoad(false);
  //       } else {
  //         toast(result?.message, { type: "error" });
  //         setPageLoad(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast(err.message, { type: "error" });
  //       setPageLoad(false);
  //     });
  // }

  let finalImages;

  const CarouselContainer = () => {
    const data =
      !_.isEmpty(postImages) && _.isArray(postImages)
        ? postImages.filter((data) => {
            return data?.type !== "thumbnail";
          })
        : [];

    const postList = data;
    finalImages = data;
    const isMoreThenOne = postList.length > 1;

    return (
      <Grid item xs={12} xl={6}>
        <div style={{ marginBottom: 20 }}>
          <Carousel
            next={() => {
              /* Do stuff */
            }}
            prev={() => {
              /* Do other stuff */
            }}
            navButtonsAlwaysVisible={isMoreThenOne}
            PrevIcon={<ArrowBackIcon />}
            NextIcon={<ArrowBackIcon style={{ transform: "rotate(180deg)" }} />}
            animation="slide"
            autoPlay={false}
            indicators={false}
            index={activeImg}
            navButtonsWrapperProps={{
              style: {
                bottom: "0px",
              },
            }}
            onChange={(index) => {
              setActiveImg(index);
            }}
            className={classes.car}
          >
            {!_.isEmpty(postList) && _.isArray(postList) ? (
              postList.map((item) => {
                const imageUrl =
                  !_.isEmpty(item?.url) &&
                  !_.isNull(item?.url) &&
                  !_.isUndefined(item?.url) &&
                  !_.isEmpty(item?.image_name) &&
                  !_.isNull(item?.image_name) &&
                  !_.isUndefined(item?.image_name)
                    ? item.url + "THUMB_" + item?.image_name
                    : "";
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 30px",
                      height: sm ? "30%" : "100%",
                    }}
                    onClick={() => {
                      if (!_.isEmpty(imageUrl)) {
                        previewModalRef?.current.open(
                          item?.url + item?.image_name
                        );
                      }
                    }}
                  >
                    {item?.type !== "video" && item?.type !== "thumbnail" ? (
                      <img
                        alt={"carousle_img"}
                        src={imageUrl}
                        className={classes.carImg}
                      />
                    ) : (
                      <video controls className={classes.carImg}>
                        <source
                          src={item?.url + item?.image_name}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt={"carousle_img"}
                  src={Files.images.colorLogoVertical}
                  className={classes.carImg}
                />
              </div>
            )}
          </Carousel>
        </div>
        {finalImages.length > 0 ? (
          <Grid item display="flex" xs={12} lg={6}>
            <Grid
              item
              textAlign={"left"}
              style={{ padding: "10px 8px", width: sm ? "80vw" : "75%" }}
            >
              <Typography variant="tableTitle">
                {`Image${finalImages.length > 0 ? "s" : ""}`}
                <Typography
                  variant="tableTitle"
                  style={{
                    fontFamily: FontFamily.RobotoRegular,
                  }}
                >
                  {!isEmpty(finalImages) && _.isArray(finalImages)
                    ? ` (${finalImages?.length})`
                    : null}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          item
          style={{
            width: lg ? "90vw" : "40vw",
            padding: "20px 10px 25px 5px",
          }}
          className={classes.scrollBar}
          gap={1}
        >
          <PostImageList />
        </Grid>
      </Grid>
    );
  };

  const PostImageList = () => {
    const data =
      !_.isEmpty(postImages) && _.isArray(postImages)
        ? postImages.filter((data) => {
            return data?.type !== "thumbnail";
          })
        : [];
    const postImagesList = data;
    // !_.isEmpty(list?.post_images) && _.isArray(list?.post_images)
    //   ? list?.post_images
    //   : [];
    const findThumbnailImage =
      !_.isEmpty(postImages) && _.isArray(postImages)
        ? postImages?.find((v) => v?.type == "thumbnail")
        : {};
    const thumbnailImageUrl =
      !_.isEmpty(findThumbnailImage) && _.isObject(findThumbnailImage)
        ? findThumbnailImage?.url + "SMALL_" + findThumbnailImage?.image_name
        : "";
    return (
      <>
        {postImagesList?.map((item, index) => {
          return (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setActiveImg(index)}
            >
              {item.type != "thumbnail" && (
                <img
                  alt="thumbnail"
                  src={
                    item?.type === "video"
                      ? thumbnailImageUrl
                      : item.url + "SMALL_" + item.image_name ||
                        Files.images.colorLogoVertical
                  }
                  className={classes.smallImgs}
                  style={{
                    border:
                      activeImg === index
                        ? `3px solid ${BaseColor.primary}`
                        : "",
                    marginRight: 10,
                  }}
                />
              )}
            </div>
          );
        })}
      </>
    );
  };

  //check Image is Compressed...
  const CheckIsImageCompressApi = (item, type, text) => {
    const endpoint = BaseSetting.endpoint.isCompress;
    const data = { id: item };
    getApiData(endpoint, "post", data)
      .then((result) => {
        if (result.status) {
          if (type === "approve") {
            changePostStatus("posted");
          } else {
            changePostStatus(type, { rejected_reason: text });
          }
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };
  const GetCountryListApi = () => {
    const endpoint = BaseSetting.endpoint.countryList;
    getApiData(endpoint, "get", {})
      .then((result) => {
        if (result.status) {
          const data = result?.data;
          setCountryList(data);
        } else {
          toast(result.message, { type: "error" });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const renderCategoryList = () => {
    const categoryList = [
      {
        id: 1,
        title: "Category",
        des:
          !_.isEmpty(list?.post_details?.category_name) &&
          !_.isUndefined(list?.post_details?.category_name) &&
          !_.isNull(list?.post_details?.category_name)
            ? list?.post_details?.category_name
            : "-",
      },
      {
        id: 2,
        title: "Subcategory",
        des:
          !_.isEmpty(list?.post_details?.subcategory_name) &&
          !_.isUndefined(list?.post_details?.subcategory_name) &&
          !_.isNull(list?.post_details?.subcategory_name)
            ? list?.post_details?.subcategory_name
            : "-",
      },
      {
        id: 3,
        title: "SubcategoryTwo",
        des:
          !_.isEmpty(list?.post_details?.subcategory_two_name?.name) &&
          !_.isUndefined(list?.post_details?.subcategory_two_name?.name) &&
          !_.isNull(list?.post_details?.subcategory_two_name?.name)
            ? list?.post_details?.subcategory_two_name?.name
            : "-",
      },
      {
        id: 4,
        title: "SubcategoryThree",
        des:
          !_.isEmpty(list?.post_details?.subcategory_three_name?.name) &&
          !_.isUndefined(list?.post_details?.subcategory_three_name?.name) &&
          !_.isNull(list?.post_details?.subcategory_three_name?.name)
            ? list?.post_details?.subcategory_three_name?.name
            : "-",
      },
      {
        id: 5,
        title: "Conditions",
        des:
          !_.isEmpty(list?.post_details?.conditions) &&
          !_.isUndefined(list?.post_details?.conditions) &&
          !_.isNull(list?.post_details?.conditions)
            ? list?.post_details?.conditions
            : "-",
      },
      {
        id: 6,
        title: "Quantity",
        des:
          list?.post_details?.quantity > 0 &&
          !_.isUndefined(list?.post_details?.quantity) &&
          !_.isNull(list?.post_details?.quantity)
            ? `${list?.post_details?.quantity} ${list?.post_details?.unit}`
            : 0,
      },
      {
        id: 7,
        title: "Supply",
        des:
          !_.isEmpty(list?.post_details?.supply) &&
          !_.isUndefined(list?.post_details?.supply) &&
          !_.isNull(list?.post_details?.supply)
            ? list?.post_details?.supply == "one_time"
              ? "One time"
              : list?.post_details?.supply == "recurring"
              ? "Recurring"
              : list?.post_details?.supply
            : "-",
      },
      {
        id: 8,
        title: "Pricing terms",
        des:
          !_.isEmpty(list?.post_details?.pricing_term) &&
          !_.isUndefined(list?.post_details?.pricing_term) &&
          !_.isNull(list?.post_details?.pricing_term)
            ? list?.post_details?.pricing_term
            : "-",
      },
      {
        id: 9,
        title: "Location",
        des:
          !_.isEmpty(list?.post_details?.country) &&
          !_.isUndefined(list?.post_details?.country) &&
          !_.isNull(list?.post_details?.country)
            ? list?.post_details?.country
            : "-",
      },
    ];

    const lastUpdatedDate = !_.isEmpty(list?.post_details?.updatedAt)
      ? moment(list?.post_details?.updatedAt).format("DD-MM-YYYY | HH:mm")
      : "";
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        {categoryList?.map((item) => {
          return (
            <div>
              <Grid
                item
                xs={12}
                style={{
                  background: BaseColor.offWhite,
                  padding: 12,
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={5}>
                  <span className={classes.cateTxt}>{item?.title}</span>
                </Grid>
                <Grid item xs={7} textAlign={"right"}>
                  <span className={classes.desTxt}>{item?.des}</span>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 12,
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} sm={12} md={12}>
              {!_.isEmpty(lastUpdatedDate) ? (
                <div
                  style={{
                    color: BaseColor.disablePrimary,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      fontFamily: FontFamily.Medium,
                      marginLeft: "-10px",
                    }}
                    className={classes.fontSize}
                  >
                    Last updated at :{" "}
                    <span
                      style={{
                        fontFamily: FontFamily.RobotoRegular,
                      }}
                      className={classes.fontSize}
                    >
                      {lastUpdatedDate} (IST)
                    </span>
                  </span>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <Grid container>
      <MiniDrawer
        header="Post Details"
        children={
          <div style={{ backgroundColor: BaseColor.whiteColor }}>
            <Grid container>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(
                    `/all-posts?page=${page}&search=${search}&sort=${sortVal}&status=${postStatus}&sort_by=${sortByVal}&start_date=${startDateVal}&end_date=${endDateVal}&download_id=${downloadId}&created_by=${createdPost}`
                  )
                }
              >
                <ArrowBackIcon /> Back
              </Button>
            </Grid>

            {pageLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <Grid
                  container
                  justifyContent={"center"}
                  style={{ paddingBottom: 20 }}
                >
                  <Grid item xs={12} lg={12}>
                    <Grid
                      container
                      gap={2}
                      className={classes.subContainer}
                      wrap={lg ? "wrap" : "nowrap"}
                    >
                      {CarouselContainer()}
                      <Grid
                        item
                        xs={12}
                        xl={6}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                          gap={sm || md || lg ? 0 : 1}
                          wrap={sm || md || lg ? "wrap" : "nowrap"}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={6}
                            lg={6}
                            xl={6}
                            style={{ marginRight: sm || md || lg ? 0 : 30 }}
                          >
                            <div
                              className={classes.startView}
                              style={{ marginBottom: 5 }}
                            >
                              <div style={{ marginBottom: 10 }}>
                                <Typography
                                  variant="tableTitle"
                                  className={classes.titleTxt}
                                >
                                  {list?.post_details?.name}
                                </Typography>
                              </div>
                      <Typography className={classes.text}>
                        {list?.post_details?.category_name}
                        {list?.post_details?.subcategory_name && `/${list?.post_details?.subcategory_name}`}
                        {list?.post_details?.subcategory_two_name?.name && `/${list?.post_details?.subcategory_two_name?.name}`}
                        {list?.post_details?.subcategory_three_name?.name && `/${list?.post_details?.subcategory_three_name?.name}`}
                    </Typography>

                              {!_.isEmpty(productId) && !_.isNull(productId) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    className={classes.titleTxt}
                                    style={{
                                      marginTop: 10,
                                      fontFamily: FontFamily.Medium,
                                    }}
                                  >
                                    {" "}
                                    Product Id :{""}
                                  </Typography>
                                  <Typography
                                    // className={classes.titleTxt}
                                    style={{
                                      fontFamily: FontFamily.RobotoMedium,
                                      fontSize: 16,
                                      textAlign: "center",
                                      marginTop: 5,
                                      marginLeft: 2,
                                    }}
                                  >
                                    {" "}
                                    {productId}
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className={classes.txtIcon}
                              style={{
                                marginBottom: 10,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  {!isEmpty(list?.post_details?.unit_price) ||
                                  !isNull(list?.post_details?.unit_price) ? (
                                    <div className={classes.priceTxt}>
                                      {
                                        currencyList.find(
                                          (v) =>
                                            v.currency_code ==
                                            list?.post_details?.currency_code
                                        )?.currency_symbol
                                      }{" "}
                                      {list?.post_details?.unit_price.toLocaleString(
                                        {
                                          style: "currency",
                                        }
                                      )}{" "}
                                      / {list?.post_details?.unit}
                                    </div>
                                  ) : (
                                    <div className={classes.priceTxt}>
                                      Price on Req.
                                    </div>
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {/* {list?.isVerified && (
                                    <CIcon
                                      src={Files.svgIcons.fillVerified}
                                      size={20}
                                      className={classes.iconStyle}
                                      style={{ marginLeft: 5 }}
                                    />
                                  )} */}
                                  {/* {list?.isPrimary && (
                                    <GiChessKing className={classes.icons} />
                                  )} */}
                                  <ReactCountryFlag
                                    countryCode={selectedCountry?.country_code}
                                    svg
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    title={list?.post_details?.country}
                                  />
                                </div>
                              </div>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={5.8}
                            lg={5.8}
                            xl={5.8}
                            style={{
                              display: "flex",
                              justifyContent:
                                sm || md ? "flex-start" : "flex-end",
                              marginTop: sm ? 20 : 0,
                            }}
                          >
                            <div
                              className={classes.endView}
                              style={{
                                width: sm && "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ marginTop: -5, width: sm && "100%" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "row",
                                  }}
                                >
                                  <div
                                    className={classes.profileView}
                                    // onClick={() =>
                                    //   userData?.isProfileComplete
                                    //     ? setShowModal(true)
                                    //     : toast(
                                    //         "First you need to complete your profile",
                                    //         { type: "error" }
                                    //       )
                                    // }
                                  >
                                    <img
                                      alt=""
                                      src={
                                        !isEmpty(list?.user_info?.user_image)
                                          ? list?.user_info?.user_image
                                          : Files.svgIcons.user
                                      }
                                      className={classes.profileImg}
                                    />
                                  </div>

                                  <div className={classes.profileTxtView}>
                                    <Typography
                                      variant="tableTitle"
                                      className={classes.userName}
                                    >
                                      {userName}
                                    </Typography>

                                    <div style={{ marginLeft: -2.5 }}>
                                      <Typography className={classes.address}>
                                        {list?.user_info?.business_info?.type ||
                                          "blank"}
                                      </Typography>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        // alignItems: "center",
                                        flexDirection: "row",
                                        marginLeft: -4,
                                      }}
                                    >
                                      <CIcon
                                        src={Files.svgIcons.location}
                                        // className={classes.socailIcon}
                                        style={{
                                          marginTop: 2,
                                          marginRight: 2,
                                          height: 14,
                                          width: 14,
                                        }}
                                      />

                                      <Typography className={classes.address}>
                                        {!isEmpty(
                                          list?.user_info?.business_info?.city
                                        ) &&
                                        !isNull(
                                          list?.user_info?.business_info?.city
                                        )
                                          ? list?.user_info?.business_info?.city
                                          : "blank"}
                                        ,{" "}
                                        {!isEmpty(
                                          list?.user_info?.business_info?.state
                                        ) &&
                                        !isNull(
                                          list?.user_info?.business_info?.state
                                        )
                                          ? list?.user_info?.business_info
                                              ?.state
                                          : "blank"}
                                        ,{" "}
                                        {!isEmpty(
                                          list?.user_info?.business_info
                                            ?.country
                                        ) &&
                                        !isNull(
                                          list?.user_info?.business_info
                                            ?.country
                                        )
                                          ? list?.user_info?.business_info
                                              ?.country
                                          : "blank"}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                {list?.post_details?.status === "in_review" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: 10,
                                    }}
                                  >
                                    {" "}
                                    <CButton
                                      btnStyle={{ width: "100%" }}
                                      variant="contained"
                                      onClick={() =>
                                        CheckIsImageCompressApi(
                                          postIdValue,
                                          "approve"
                                        )
                                      }
                                      loading={approveBtnLoading}
                                      disabled={disableBtn}
                                    >
                                      Approve
                                    </CButton>
                                    <CButton
                                      btnStyle={{
                                        width: "100%",
                                        marginLeft: "10px",
                                      }}
                                      variant="outlined"
                                      onClick={() => setRejectModal(true)}
                                      loading={rejectBtnLoading}
                                      disabled={disableBtn}
                                    >
                                      Reject
                                    </CButton>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {/* //bottomView */}
                        {!isEmpty(list?.post_details?.description) &&
                        !isNull(list?.post_details?.description) ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginTop: 20,
                              }}
                            >
                              <Typography
                                variant="tableTitle"
                                className={classes.blackTxt}
                              >
                                Description
                              </Typography>
                            </div>
                            <Grid
                              style={{
                                marginTop: 10,
                                wordBreak: "break-word",
                              }}
                              container
                              flexWrap={"wrap"}
                            >
                              <span
                                className={classes.descriptionTxt}
                                style={{
                                  // width: "100%",
                                  height: checked
                                    ? list?.post_details?.description.split(
                                        /\r\n|\r|\n/
                                      ).length > 3 && 70
                                    : "auto",
                                }}
                              >
                                {/* <Typography>{formattedDescription}</Typography> */}
                                {readMore &&
                                list?.post_details?.description.length > 300
                                  ? list?.post_details?.description.slice(
                                      0,
                                      300
                                    )
                                  : list?.post_details?.description}

                                <span
                                  style={{
                                    fontFamily: FontFamily.Bold,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setReadMore(!readMore)}
                                >
                                  {list?.post_details?.description.length > 300
                                    ? readMore &&
                                      list?.post_details?.description.length >
                                        300
                                      ? "...Read more"
                                      : !readMore
                                      ? "...Read less"
                                      : ""
                                    : ""}
                                </span>
                                {list?.post_details?.description.split(
                                  /\r\n|\r|\n/
                                ).length > 3 && (
                                  <div
                                    style={{
                                      marginLeft: 6,
                                    }}
                                  >
                                    {/* <span
                                style={{
                                  fontSize: 12,
                                  color: BaseColor.primary,
                                  cursor: "pointer",
                                }}
                                onClick={() => setChecked(!checked)}
                              >
                                {!checked ? "See more" : "See less"}
                              </span> */}
                                  </div>
                                )}
                              </span>
                            </Grid>
                            {renderCategoryList()}
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        }
      />
      <CModal
        visible={rejectModal}
        title={"Rejected Reason"}
        onClose={() => {
          setRejectModal(false);
          allErrorFalse();
          setRejectText();
        }}
        style={{ width: "40%" }}
        children={
          <div style={{ padding: "20px" }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <CInput
                  placeholder="Enter Rejected Reason"
                  errorMsg={rejectErrorText}
                  onChange={(val) => {
                    setRejectText(val);
                    allErrorFalse();
                  }}
                  value={rejectText}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6} paddingRight={"10px"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRejectModal(false);
                    setRejectText();
                    allErrorFalse();
                  }}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                >
                  No
                </Button>
              </Grid>

              <Grid item xs={6}>
                <CButton
                  variant="contained"
                  onClick={createRejectValidation}
                  style={{ width: "100%" }}
                  disabled={disableBtn}
                >
                  Yes
                </CButton>
              </Grid>
            </Grid>
          </div>
        }
      />
      <CPreviewModal ref={previewModalRef} />
    </Grid>
  );
};

export default ProductDetail;
